<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">
      <v-card-text>
        <v-card-title style="margin-left:-15px;">Add {{type.charAt(0).toUpperCase() + type.slice(1)}} Note</v-card-title>
        <div style="color:gray">Item: {{backorder.product_number}}</div>
        <div style="color:gray">Order: {{backorder.order_number}}</div>
        <v-textarea variant="outlined" height="20" :placeholder="type.charAt(0).toUpperCase() + type.slice(1) + ' Note'" v-model="note"></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveNote()" :disabled="note.trim() === ''">SAVE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'

export default {
  name: 'NoteItemPopUp',
  components: {},
  mixins: [],
  props:{
    backorderkey: String,
    type: String,
  },
  data(){
    return{
      dialog: true,
      backorder: null,
      note: '',
      submitted_by_email: '',
      submitted_by_name: '',
      submitted_date: ''
    }
  },
  beforeMount(){
    this.backorder = this.db.backorders[this.backorderkey]
    this.submitted_by_email = this.db.users[this.user].email
    this.submitted_by_name = this.db.users[this.user].first_name + ' ' + this.db.users[this.user].last_name
  },
  computed: {
    ...mapGetters(['user','db']),
  },
  methods:{
    saveNote(){
      var self = this

      if(self.type === 'shared'){
        firebase.database().ref('backorders').child(self.backorderkey).child('inquiries').push({note: self.note, submitted_by_name: self.submitted_by_name, submitted_by_email: self.submitted_by_email, submitted_date: Date.now()}).then(function(){
          self.$emit('close')
        })
      }

      if(self.type === 'internal'){
        firebase.database().ref('backorders').child(self.backorderkey).child('internal_notes').push({note: self.note, submitted_by_name: self.submitted_by_name, submitted_by_email: self.submitted_by_email, submitted_date: Date.now()}).then(function(){
          self.$emit('close')
        })
      }

    }
  }
}
</script>

<style>

</style>


