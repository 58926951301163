<template>
  <div class="sales-dashboard">
    <div style="font-size: 22px; color: gray;padding:10px">Proactive ETAs</div>
    <div v-if="showTable" style="margin:10px">
      <v-row>
        <v-col cols="12">
          <v-card height="475px" elevation="12">
            <v-card-title>
            </v-card-title>
            <v-card-text>
              <v-row>

                <v-col cols="4">
                  <div>
                    <v-text-field label="Search..." variant="solo" density="compact" hide-details="true" @input="debouncedSearch($event)"></v-text-field>
                  </div>
                </v-col>

                <v-col cols="2">
                  <v-select
                    :items="specialists_arr"
                    label="Account Specialist"
                    v-model="$store.state.selectedSpecialist"
                    density="compact"
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-select
                    :items="executives_arr"
                    label="Account Executive"
                    v-model="$store.state.selectedExecutive"
                    density="compact"
                    hide-details="auto"
                  ></v-select>
                </v-col>                
                <v-col cols="4">
                  <v-row class="checkbox-row">
                    <v-col cols="4">
                      <v-checkbox @input="debouncedUpdateFilter" v-model='status_filter_ui' class="custom-checkbox" label="Due Now" value="Due Now" color="#ff7214" hide-details="auto"></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox @input="debouncedUpdateFilter" v-model='status_filter_ui' class="custom-checkbox" label="Due Soon" value="Due Soon" color="#ff7214" hide-details="auto"></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox @input="debouncedUpdateFilter" v-model='status_filter_ui' class="custom-checkbox" label="Complete" value="Complete" color="#ff7214" hide-details="auto"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <Vue3EasyDataTable
                :headers="order_status_updates_headers"
                :items="order_status_updates_items"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="300"
                :search-value="searchValue"
              >
                <template #item-customer_name="item">
                  <router-link :to="{ name: 'customer', params: {customerpageid: item.page}}" target="_blank" class="dashboard-link">{{item.customer_name}}</router-link>
                </template>

                <template #item-eta_update_status="item">
                  <div :class="statusClass(item.eta_update_status)">
                    {{ item.eta_update_status }}
                  </div>
                </template>

                <template #item-last_eta_update_sortable="item">
                  {{item.last_eta_update ? formatDateTime(item.last_eta_update) : ''}}
                </template>

                <template #item-eta_update_provided="item">
                  <v-btn style="background:#ff7214;color:white;width:150px;height:20px;font-size:10px;margin:5px 0px" @click="etaUpdateProvided(item)">SEND ETA UPDATE</v-btn>
                </template>
              </Vue3EasyDataTable>

              <v-row>
                <v-col style="color:gray;margin-top:10px">
                  <div style="padding-bottom:4px">Customers: {{order_status_updates_items.length}}</div>
                  <div style="padding-bottom:4px">Backorder Lines: {{total_backorder_lines}}</div>
                  <div style="padding-bottom:4px">Non-Current ETAs: {{total_non_current_etas}}</div>
                  <div style="font-style:italic;font-size:10px">These totals do not take into account typed search</div>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment-business-days'
import _ from 'lodash'
import XLSX from 'xlsx'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { debounce } from 'lodash';

export default {
  name: 'SalesDashboard',
  components: {Vue3EasyDataTable},
  data(){
    return{
      searchValue: '',
      intervalId: null,
      currentDate: new Date(),
      status_filter_ui: ['Due Now'],
      status_filter: ['Due Now'],
      specialists_arr: ['All'],
      executives_arr: ['All'],
      order_status_updates_headers: [
        { text: "Customer Name", value: "customer_name", sortable: true },
        { text: "Account Specialist", value: "account_specialist", sortable: true },
        { text: "Account Executive", value: "salesperson_code", sortable: true },
        { text: "Master Customer AR Number", value: "master_ar_account", sortable: true },
        { text: "# Backorder Lines", value: "backorder_lines_count", sortable: true },
        { text: "# Non-Current ETAs", value: "non_current_etas_count", sortable: true },
        { text: "ETA Update Status", value: "eta_update_status", sortable: true },
        { text: "Last ETA Update", value: "last_eta_update_sortable", sortable: true },
        { text: "", value: "eta_update_provided", sortable: false },
      ],
      customer_eta_update_statuses: {},
      showTable: false,
      total_non_current_etas: 0,
      total_backorder_lines: 0
    }
  },
  watch: {
    status_filter_ui(){
      this.$store.state.isLoading = true
    }
  },
  mounted(){
    var self = this
    self.intervalId = setInterval(() => {
      self.currentDate = new Date()
    }, 60000)
    self.debouncedUpdateFilter = debounce(self.updateFilter, 300);
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }    
    var self = this
    self.$store.state.isLoading = true
    setTimeout(() => {
      self.showTable = true;
      self.$store.state.isLoading = false
    }, 2000);
  },  
  beforeUnmount(){
    clearInterval(this.intervalId)
  },
  computed: {
    ...mapGetters(['user','db','selectedSpecialist','selectedExecutive']),
    order_status_updates_items(){
      var self = this
      var arr = []
      var customers = JSON.parse(JSON.stringify( self.db.customers))
      var date_now = Moment()

      self.total_non_current_etas = 0
      self.total_backorder_lines = 0

      for(var cid in customers){
        var customer = customers[cid]

        if(customer.has_dedicated_eta_manager === 'Yes, Account Specialist' && customer.master_ar_account === customer.customer_number){

          if(this.specialists_arr.indexOf(customer.account_specialist) === -1){
            this.specialists_arr.push(customer.account_specialist)
          }

          if(this.executives_arr.indexOf(customer.salesperson_code) === -1){
            this.executives_arr.push(customer.salesperson_code)
          }
          this.specialists_arr.sort()
          this.executives_arr.sort()
          var backorder_object = self.calculateBackorderCnts(customer)
          customer.backorder_lines_count = backorder_object.backorder_lines_count
          customer.non_current_etas_count = backorder_object.non_current_etas_count

          if(customer.backorder_lines_count > 0){
            var last_eta_update_date = customer.last_eta_update ? Moment(customer.last_eta_update) : false
            var provide_eta_updates_every_x_days = parseFloat(customer.send_eta_update_every_x_days)

            customer.id = cid
            customer.date_hack_to_update_time = self.currentDate

            if(last_eta_update_date){
              var days_difference = date_now.diff(last_eta_update_date, 'days')
              if (days_difference >= provide_eta_updates_every_x_days) {
                customer.eta_update_status = 'Due Now';
              } else if (provide_eta_updates_every_x_days - days_difference <= 3) {
                customer.eta_update_status = 'Due Soon';
              } else if (days_difference < provide_eta_updates_every_x_days - 3) {
                customer.eta_update_status = 'Complete';
              }
            }else{
              customer.eta_update_status = 'Due Now'
            }

            customer.last_eta_update_sortable = customer.last_eta_update ? Moment(customer.last_eta_update).valueOf() : ''

            if(this.status_filter.indexOf(customer.eta_update_status) !== -1 && (self.selectedSpecialist === 'All' || self.selectedSpecialist === customer.account_specialist) && (self.selectedExecutive === 'All' || self.selectedExecutive === customer.salesperson_code) ){
              this.total_non_current_etas += customer.non_current_etas_count
              this.total_backorder_lines += customer.backorder_lines_count
              arr.push(customer)
            }
          }
        }
      }
      return arr
    }
  },
  methods:{
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value
    }, 500),    
    updateFilter(value) {
      this.status_filter = JSON.parse(JSON.stringify( this.status_filter_ui))
      this.$store.state.isLoading = false
    },
    statusClass(status) {
      switch (status) {
        case 'Complete':
          return 'status-complete';
        case 'Due Soon':
          return 'status-due-soon';
        case 'Due Now':
          return 'status-due-now';
        default:
          return '';
      }
    },
    calculateBackorderCnts(customer){
      var cnts = {backorder_lines_count: 0,non_current_etas_count:0 }
      var ar_accounts = customer.child_ar_accounts ? customer.child_ar_accounts : []

      var backorders = JSON.parse(JSON.stringify( this.db.backorders))

      ar_accounts.push(customer.customer_number)

      for (var backorder in backorders) {
        var backorder = backorders[backorder]
        if(ar_accounts.indexOf(backorder.customer_number) !== -1 && !backorder.completed){

          cnts.backorder_lines_count++

          var purch_order = this.db.purchase_orders[backorder.linked_po_number]

          if(purch_order){
            for(var item_key in purch_order.items){

              if(purch_order.items[item_key].product === backorder.product_number){
                backorder.eta = purch_order.items[item_key].eta
                if(backorder.eta === 'UTP' || backorder.eta === 'TBD' || backorder.eta === '' || Moment(backorder.eta, 'M/DD/YY').valueOf() < Moment().startOf('day').valueOf()){
                  if(purch_order.items[item_key].line_complete !== 'Y'){
                    cnts.non_current_etas_count++
                  }
                }
                break;
              }
            }
          }else{
            var potential_etas = []
            var earliest_eta_date = null;
            for(var po in this.db.purchase_orders){
              if (/[a-zA-Z]/.test(po)) { //make sure PO is not a special order with a letter
                continue;
              }
              var items = this.db.purchase_orders[po].items
              for(var item in items){
                var eta = items[item].eta
                if(items[item].product === backorder.product_number && eta !== '' && eta !== 'UTP' && eta !== 'TBD' && items[item].line_complete !== 'Y'){
                  potential_etas.push(eta)
                  break;
                }
              }
            }
            for (var i = 0; i < potential_etas.length; i++) {
              var eta = potential_etas[i];
              if (earliest_eta_date === null || Moment(eta, 'MM/DD/YY').isBefore(earliest_eta_date, 'day')) {
                earliest_eta_date = Moment(eta, 'MM/DD/YY');
              }
            }
            if (earliest_eta_date === null || Moment(earliest_eta_date, 'M/DD/YY').valueOf() < Moment().startOf('day').valueOf() ) {
              cnts.non_current_etas_count++
            }
          }
        }
      }
      return cnts
    },
    etaUpdateProvided(customer){
      var self = this
      
      if(customer.eta_contact_emails.length === 0){
        alert('No customer email provided. Please add to Inform.')
      }
      
      var msg = 'Click \'OK\' to provide an ETA update for ' + customer.customer_name
      var eta_sent = confirm(msg)
      var cc = encodeURIComponent(customer.salesperson_code === 'JBS' ? 'jsmith@riverhawkindustrialsupply.com' : '')

      if(eta_sent){
        var timestamp = Date.now()
        firebase.database().ref('customers').child(customer.id).child('eta_updates').push({timestamp: timestamp, sent_by: self.user})
        firebase.database().ref('customers').child(customer.id).child('last_eta_update').set(timestamp)

        var to = encodeURIComponent(customer.eta_contact_emails)
        var subject = encodeURIComponent('Riverhawk ETA Updates');
        var page = encodeURIComponent('https://riverhawknest.com/customer/'+customer.page)
        var body = 'Dear Valued Customer,%0D%0A%0D%0APlease follow this link to view your backorder ETAs:%0D%0A%0D%0A'+page+'%0D%0A%0D%0AIf you have any questions or concerns regarding your backorders, please leave a note using the Inquire button. I would also be happy to advise on available substitutes.'+'%0D%0A%0D%0AThank you,%0D%0A%0D%0A' + this.db.users[this.user].first_name + ' ' + this.db.users[this.user].last_name

        if(customer.salesperson_code === 'JBS'){
          window.location.href = 'mailto:' + to+'?cc=' + cc +'&subject=' + subject + '&body='+ body
        }else{
          window.location.href = 'mailto:' + to+'?subject=' + subject + '&body='+ body
        }
      }
    },
    formatDateTime(date){
      return Moment(date).format('MM/DD/YY hh:mma')
    }
  }
}
</script>

<style scoped>

  .v-card .v-card-title{
    color: gray;
    font-size: 14px;
  }

  .v-card .v-card-text {
    line-height: 100%;
  }

  .table-container{
    position: relative;
    top: 5px;
    margin:0px 20px;
  }

  .flagged{
    width: 20px;
    height: 20px;
    border:1px solid #ff7214;
    background: #ff7214;
    cursor: pointer;
  }

  .unflagged{
    width: 20px;
    height: 20px;
    border:1px solid #ff7214;
    cursor: pointer;
  }

  .needs_attention{
    background: red;
    color: white;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 140%;
    font-weight: bold;
    border-radius: 100%;
  }

  a{
    text-decoration: none;
  }

  .v-card-actions{
    min-height: 0px;
    padding: 4px 0px;
  }

  .dashboard-link{
    color:#ff7214;
    cursor:pointer;
    font-weight: bold;
    font-size: 11px;
  }

.status-complete {
    color: green;
    width: 70px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
}

.status-due-soon {
    background-color: #FFA500;
    color: white;
    width: 70px;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    font-size: 12px;
}

.status-due-now {
    background-color: red;
    color: white;
    width: 70px;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    font-size: 12px;
}

.checkbox-row {
  width: 375px;
  float: right;
}

>>>.custom-checkbox .v-label {
  font-size: 0.8rem;
}

::v-deep .v-select__selections {
  height: 50px !important;
  min-height: 50px !important;
}

</style>

