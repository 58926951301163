<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">
      <v-card-text>

        <v-card-title style="margin-left:-15px"></v-card-title>
        <div style="font-weight:bold">PO {{po}}</div>
        <div style="font-weight:bold;margin-bottom:10px">Item {{item.manufacturer_part_number}}</div>
        <!-- <div>Shipping Company</div><v-text-field variant="outlined" placeholder="Shipping Company" v-model="shipping_company"></v-text-field> -->
        <div>Shipping Company</div>
        <v-combobox
          variant="outlined"
          v-model="shipping_company"
          :items="shippingCompanies"
          placeholder="Shipping Company"
          clearable
        ></v-combobox>

        <div>Tracking Number</div><v-text-field variant="outlined" placeholder="Tracking Number" v-model="tracking_number"></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveItemInfo()" :disabled="!updates_made">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Datepicker from 'vuejs3-datepicker';

export default {
  name: 'UpdateShippingInfoInfoPopUp',
  components: {Datepicker},
  props:{
    po: String,
    itemkey: String
  },
  data(){
    return{
      dialog: true,
      item: null,
      original_shipping_company: '',
      original_tracking_number: '',
      shipping_company: '',
      tracking_number: ''
    }
  },
  beforeMount(){
    var item_clone = JSON.parse(JSON.stringify( this.db.purchase_orders[this.po].items[this.itemkey] ))
    this.item = item_clone
    this.original_shipping_company = item_clone.shipping_company
    this.original_tracking_number = item_clone.tracking_number
    this.shipping_company = item_clone.shipping_company
    this.tracking_number = item_clone.tracking_number
  },
  computed: {
    ...mapGetters(['user','db']),
    updates_made(){
      return this.original_shipping_company.trim() !== this.shipping_company.trim() || this.original_tracking_number.trim() !== this.tracking_number.trim()
    },
    shippingCompanies(){
      return this.db.shipping_companies
    }
  },
  watch: {
    shipping_company(newVal) {
      if (newVal === null) {
        this.shipping_company = '';
      }
    }
  },  
  methods:{
    saveItemInfo(){
      var self = this
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('shipping_company').set( self.shipping_company ).then(function(){
        firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('tracking_number').set( self.tracking_number ).then(function(){
          self.$emit('close')
        })
      })

    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .vuejs3-datepicker__icon{
    padding-top: 2px;
  }
  .vuejs3-datepicker__value{
    padding: 5px 15px;
  }
  .vuejs3-datepicker__calendar{

  }
  .vuejs3-datepicker__calendar-topbar{
    display: none;
  }
</style>


