<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">
      <v-card-text>

        <v-card-title style="margin-left:-15px">{{vendor_name}}</v-card-title>
        <div style="font-weight:bold">Default ETA Days</div>
        <v-text-field variant="outlined" placeholder="Default ETA Days" type="number"  hide-details="auto" v-model.number="default_eta_days" min="0"></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveDefaultEta()" :disabled="!valid_number">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'VendorDefaultEtaPopUp',
  components: {},
  props:{
    vendorkey: String
  },
  data(){
    return{
      dialog: true,
      item: null,
      vendor_name: '',
      default_eta_days: 0,
      original_default_eta_days: 0
    }
  },
  beforeMount(){
    var vendor_clone = JSON.parse(JSON.stringify( this.db.vendors[this.vendorkey] ))
    this.vendor_name = vendor_clone.name
    this.original_default_eta_days = vendor_clone.default_eta_days
    this.default_eta_days = vendor_clone.default_eta_days
  },
  computed: {
    ...mapGetters(['user','db']),
    valid_number(){
      return this.default_eta_days !== this.original_default_eta_days && this.default_eta_days && this.default_eta_days % 1 == 0
    }
  },
  methods:{
    saveDefaultEta(){
      var self = this
      firebase.database().ref('vendors').child(self.vendorkey).child('default_eta_days').set( self.default_eta_days ).then(function(){
        self.$emit('close')
      })
    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .vuejs3-datepicker__icon{
    padding-top: 2px;
  }
  .vuejs3-datepicker__value{
    padding: 5px 15px;
  }
  .vuejs3-datepicker__calendar{

  }
  .vuejs3-datepicker__calendar-topbar{
    display: none;
  }
</style>


