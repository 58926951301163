<template>
  <v-dialog scrollable persistent v-model="dialog" height="100vh">
    <v-card width="100vw">
      <v-card-text>
        <v-card-title style="margin-left:-15px"></v-card-title>
        <div style="font-weight:bold">Update Tracking Information for PO {{po}}</div>

        <v-row>
          <v-col cols=6>
            <div>Tracking Number</div><v-text-field variant="outlined" placeholder="Tracking Number" v-model="tracking_number"></v-text-field>
          </v-col>          
          <v-col cols=6>
          <div>Shipping Company</div>
          <v-combobox
            variant="outlined"
            v-model="shipping_company"
            :items="shippingCompanies"
            placeholder="Shipping Company"
            clearable
          ></v-combobox>
            
          </v-col>
        </v-row>

<!--         <v-table fixed-header height="60vh" style="border:1px solid lightgray">
          <thead>
            <tr>
              <th><div style="width:80px"><a href="#" @click="toggleSelectAll($event)" style="color: #ff7214; text-decoration: none; font-size:12px;">{{ selectAllText }}</a></div></th>
              <th>Product</th>
              <th>Manufacturer Product Number</th>
              <th>Vendor Product Number</th>
              <th>Product Description</th>
              <th>Current Tracking Number</th>
              <th>Current Shipping Company</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in po_items">
              <td><v-checkbox v-model="selected_items" :value="item.item_key" color="#ff7214" hide-details></v-checkbox></td>
              <td>{{item.product}}</td>
              <td>{{item.manufacturer_part_number}}</td>
              <td>{{item.vendor_part_number}}</td>
              <td>{{item.product_description}}</td>
              <td>{{item.tracking_number}}</td>
              <td>{{item.shipping_company}}</td>
            </tr>
          </tbody>
        </v-table>
 -->

           <div style="margin:10px 10px 0px 0px;border-top:1px solid gray;padding-top:10px">
            <v-text-field style="width: 400px;margin-bottom: 10px;" label="Search Items..." variant="solo" density="comfortable" hide-details="true" @input="debouncedSearch($event)"></v-text-field>
          </div>

          <Vue3EasyDataTable
            :headers="items_headers"
            :items="po_items"
            :search-value="searchValueItems"
            :table-height="table_height"
            :rows-per-page="50000"
            :hide-rows-per-page="true"
            :hide-footer="false"
          >

          <template #header-item_key="header">
            <div style="width:80px" v-if="searchValueItems.length < 1"><a href="#" @click="toggleSelectAll($event)" style="color: #ff7214; text-decoration: none; font-size:12px;">{{ selectAllText }}</a></div>
          </template>

          <template #item-item_key="item">
            <v-checkbox v-model="selected_items" :value="item.item_key" color="#ff7214" hide-details></v-checkbox>
          </template>

          </Vue3EasyDataTable>

      </v-card-text>

      <v-card-actions>
      
        <v-spacer></v-spacer>
        <div style="margin-right:20px">{{ selected_items.length + ' product' + (selected_items.length !== 1 ? 's' : '') + ' selected' }}</div>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveTracking()" :disabled="selected_items.length === 0">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Datepicker from 'vuejs3-datepicker';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { debounce } from 'lodash';

export default {
  name: 'BulkTrackingPopUp',
  components: {Datepicker,Vue3EasyDataTable},
  props:{
    po: String,
  },
  data(){
    return{
      dialog: true,
      shipping_company: '',
      tracking_number: '',
      selected_items: [],
      isAllSelected: false,
      table_height: 400,
      
      searchValueItems: '',
      items_headers: [
        { text: "", value: "item_key", sortable: false },
        { text: "Product", value: "product", sortable: true },
        { text: "Manufacturer Product Number", value: "manufacturer_part_number", sortable: true },
        { text: "Vendor Product Number", value: "vendor_part_number", sortable: true },
        { text: "Product Description", value: "product_description", sortable: true },
        { text: "Current Tracking Number", value: "tracking_number", sortable: true },
        { text: "Current Shipping Company", value: "shipping_company", sortable: true },
      ],  
    }
  },
  beforeMount(){

  },
  computed: {
    ...mapGetters(['user','db']),
    po_items(){
      var purchase_order_clone = JSON.parse(JSON.stringify( this.db.purchase_orders[this.po] ))
      var po_items_arr = []
      for(var item_key in purchase_order_clone.items){
        purchase_order_clone.items[item_key].item_key = item_key
        po_items_arr.push(purchase_order_clone.items[item_key])
      }

      return po_items_arr
    },
    selectAllText() {
      return this.isAllSelected ? 'Unselect All' : 'Select All';
    },
    shippingCompanies(){
      return this.db.shipping_companies
    }    
  },
  shipping_company(newVal) {
    if (newVal === null) {
      this.shipping_company = '';
    }
  },
  methods:{
    debouncedSearch: debounce(function(event) {
      this.searchValueItems = event.target._value
    }, 500),     
    toggleSelectAll() {
      event.preventDefault();
      this.isAllSelected = !this.isAllSelected;

      if (this.isAllSelected) {
        this.selected_items = this.po_items.map(item => item.item_key);
      } else {
        this.selected_items = [];
      }
    },    
    saveTracking(){
      var self = this
      var po = this.po
      var shipping_company = this.shipping_company.trim()
      var tracking_number = this.tracking_number.trim()
      var items = this.selected_items
      var updates = {};

      items.forEach(itemKey => {
        updates[`purchase_orders/${po}/items/${itemKey}/shipping_company`] = shipping_company;
        updates[`purchase_orders/${po}/items/${itemKey}/tracking_number`] = tracking_number;
      });

      firebase.database().ref().update(updates).then(() => {
        self.$emit('close');
      }).catch(error => {
        console.error('Error updating tracking information:', error);
      });

    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .vuejs3-datepicker__icon{
    padding-top: 2px;
  }
  .vuejs3-datepicker__value{
    padding: 5px 15px;
  }
  .vuejs3-datepicker__calendar{

  }
  .vuejs3-datepicker__calendar-topbar{
    display: none;
  }
>>>.custom-checkbox .v-label {
  font-size: 0.8rem;
}

</style>


