<template>
  <div class="vendor">

    <div>
      <v-card-title>{{vendor_info ? vendor_info.name : 'Loading....'}}</v-card-title>

      <v-card-subtitle v-if="vendor_info">PO Confirmation Email: {{vendor_info.po_confirmation_email}}</v-card-subtitle>
      <v-card-subtitle v-if="vendor_info">ETA Inquiry Email: {{vendor_info.eta_inquiry_email}}</v-card-subtitle>
      <v-card-subtitle v-if="vendor_info">Escalation Email: {{vendor_info.escalation_email}}</v-card-subtitle>
      <v-card-subtitle v-if="vendor_info">Default ETA Days: {{vendor_info.default_eta_days}}</v-card-subtitle>

      <v-card-subtitle v-if="vendor_info && pending_purchase_orders_arr.length === 0 && pending_eta_items.length === 0"><div style="color:green;font-size:18px;font-weight:bold">Nice work, we're all caught up!</div></v-card-subtitle>

      <v-card>
        <v-card-subtitle class="table-title">{{vendor_info ? pending_purchase_orders_arr.length + ' Purchase Orders Pending Confirmation' : ''}} </v-card-subtitle>
          <div class="table-container">
            <Vue3EasyDataTable
              :headers="po_headers"
              :items="pending_purchase_orders_arr"
              :rows-per-page="2000"
              :hide-rows-per-page="true"
              :hide-footer="true"
              :table-height="50 * pending_purchase_orders_arr.length"
              v-if="pending_purchase_orders_arr.length"
            >
            <template #item-po_date_sortable="item">
              {{item.po_date_formatted}}
            </template>
            <template #item-shared_notes="item">
              <div v-for="n in item.shared_notes" style="border-bottom:1px dashed;margin-top:5px;margin-bottom:5px">
                <div style="font-style:italic">{{n.note}}</div>
                <div style="font-size:8px;line-height:100%">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
              </div>
            </template>
            <template #item-buttons="item">
              <div style="text-align:right">
                <v-btn color="green" size="x-small" style="margin:5px" @click="openPurchaseOrderConfirmationPopUp(item.po_number)">Confirm PO</v-btn>
                <v-btn color="red" size="x-small" style="margin:5px" @click="openDisputePurchaseOrderPopUp(item.po_number)">Dispute PO</v-btn>
              </div>
            </template>
          </Vue3EasyDataTable>
        </div>
      </v-card>

      <v-card style="margin-top:10px">
        <v-card-subtitle class="table-title">{{vendor_info ? pending_eta_items.length + ' Items Pending ETA' : ''}} </v-card-subtitle>
          <div class="table-container">
            <Vue3EasyDataTable
              :headers="item_headers"
              :items="pending_eta_items"
              :rows-per-page="2000"
              :hide-rows-per-page="true"
              :hide-footer="true"
              :table-height="50 * pending_eta_items.length"
              v-if="pending_eta_items.length"
            >

            <template #item-po_date_sortable="item">
              {{item.po_date_formatted}}
            </template>

            <template #item-eta_sortable="item">
              {{item.eta}}
            </template>

            <template #item-shared_notes="item">
              <div v-for="n in item.shared_notes" style="border-bottom:1px dashed;margin-top:5px;margin-bottom:5px">
                <div style="font-style:italic">{{n.note}}</div>
                <div style="font-size:8px;line-height:100%">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
              </div>
            </template>

            <template #item-buttons="item">
              <div style="text-align:right">
                <v-btn color="orange" size="x-small" style="margin:5px" @click="openItemInfoPopUp(item.po_number, item.item_key)">Provide Updated ETA</v-btn>
              </div>
            </template>

          </Vue3EasyDataTable>
        </div>
      </v-card>

      <v-card style="margin-top:10px">
        <v-card-subtitle class="table-title">{{vendor_info ? utp_tbd_items.length + ' Items ETA Marked Unable To Provide (UTP) or TBD' : ''}} </v-card-subtitle>
          <div class="table-container">
            <Vue3EasyDataTable
              :headers="item_headers"
              :items="utp_tbd_items"
              :rows-per-page="2000"
              :hide-rows-per-page="true"
              :hide-footer="true"
              :table-height="50 * utp_tbd_items.length"
              v-if="utp_tbd_items.length"
            >

            <template #item-po_date_sortable="item">
              {{item.po_date_formatted}}
            </template>

            <template #item-eta_sortable="item">
              {{item.eta}}
            </template>

            <template #item-shared_notes="item">
              <div v-for="n in item.shared_notes" style="border-bottom:1px dashed;margin-top:5px;margin-bottom:5px">
                <div style="font-style:italic">{{n.note}}</div>
                <div style="font-size:8px;line-height:100%">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
              </div>
            </template>

            <template #item-buttons="item">
              <div style="text-align:right">
                <v-btn color="orange" size="x-small" style="margin:5px" @click="openItemInfoPopUp(item.po_number, item.item_key)">Provide Updated ETA</v-btn>
              </div>
            </template>

          </Vue3EasyDataTable>
        </div>  
      </v-card>

        <span class="version-footer">
          {{$store.state.version}}
        </span>
    </div>

    <PurchaseOrderConfirmationPopUp v-if="show_po_confirmation_popup" :po='selected_po_for_component' :vendor='vendor_info' @close="closePurchaseOrderConfirmationPopUp()" />
    <PurchaseOrderDetailPopUp v-if="show_po_detail_popup" :po='selected_po_for_component' @close="closePurchaseOrderDetailPopUp()" />
    <DisputePurchaseOrderPopUp v-if="show_dispute_po_popup" :po='selected_po_for_component' @close="closeDisputePurchaseOrderPopUp()" />
    <VendorUpdateItemInfoPopUp v-if="show_vendor_update_item_info_popup" :po='selected_po_for_component' :itemkey='selected_item_for_component' @close="closeItemInfoPopUp()" />

  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import PurchaseOrderConfirmationPopUp from '@/components/PurchaseOrderConfirmationPopUp.vue'
import PurchaseOrderDetailPopUp from '@/components/PurchaseOrderDetailPopUp.vue'
import DisputePurchaseOrderPopUp from '@/components/DisputePurchaseOrderPopUp.vue'
import VendorUpdateItemInfoPopUp from '@/components/VendorUpdateItemInfoPopUp.vue'
import Vue3EasyDataTable from 'vue3-easy-data-table';

export default {
  name: 'VendorView',
  components: {PurchaseOrderConfirmationPopUp,PurchaseOrderDetailPopUp,DisputePurchaseOrderPopUp,VendorUpdateItemInfoPopUp,Vue3EasyDataTable},
  data(){
    return {
      vendor_page_id: this.$route.params['vendorpageid'],
      vendor_info: null,
      purchase_orders: null,
      selected_po_for_component: null,
      selected_item_for_component: null,
      show_po_confirmation_popup: false,
      show_po_detail_popup: false,
      show_dispute_po_popup: false,
      show_vendor_update_item_info_popup: false,
      pending_purchase_orders_arr: [],
      pending_eta_items: [],
      utp_tbd_items: [],
      po_headers: [
        { text: "PO Number", value: "po_number", sortable: true },
        { text: "PO Date", value: "po_date_sortable", sortable: true },
        { text: "Buyer Name", value: "buyer_name", sortable: true },
        { text: "Shared Notes", value: "shared_notes", sortable: false },
        { text: "PO Confirmation Status", value: "po_confirmation_status", sortable: false },
        { text: "", value: "buttons", sortable: false },
      ],
      item_headers: [
        { text: "RH Product #", value: "product", sortable: true },
        { text: "Mfg Product #", value: "manufacturer_part_number", sortable: true },
        { text: "Vendor Product #", value: "vendor_part_number", sortable: true },
        { text: "Description", value: "product_description", sortable: true },
        { text: "Qty Owed", value: "qty_open", sortable: true },
        { text: "Shared Notes", value: "shared_notes", sortable: true },
        { text: "PO Number", value: "po_number", sortable: true },
        { text: "PO Date", value: "po_date_sortable", sortable: true },
        { text: "Previously Provided ETA", value: "eta_sortable", sortable: true },
        { text: "", value: "buttons", sortable: false },
      ]
    }
  },
  beforeMount(){
    var self = this
    firebase.database().ref('vendors').child('vendor_'+self.vendor_id).once('value', function(vendor_snapshot) {
      self.vendor_info = vendor_snapshot.val()

      firebase.database().ref('analytics').push({
        page: 'vendor',
        vendor_name: self.vendor_info.name,
        vendor_id: 'vendor_'+self.vendor_id,
        type: localStorage.getItem('isNestUser') === 'true' ? 'internal' : 'external',
        date: Date.now(),
        user: localStorage.getItem('isNestUser') === 'true' ? localStorage.getItem('nestUser') : 'vendor',
        app_version: self.$store.state.version
      })

    })
    firebase.database().ref('purchase_orders').orderByChild('vendor_number').equalTo(self.vendor_id).on('value', function(vendor_purchase_orders_snapshot) {
      var purchase_orders = vendor_purchase_orders_snapshot.val()
      self.pending_purchase_orders_arr = []
      self.pending_eta_items = []
      self.utp_tbd_items = []
      for(var po in purchase_orders){

        purchase_orders[po].po_confirmation_status = self.po_confirmation_status(purchase_orders[po])
        purchase_orders[po].po_date_sortable = Moment(purchase_orders[po].po_date).startOf('day')
        purchase_orders[po].po_date_formatted = self.formatDate(purchase_orders[po].po_date_sortable)
        if(purchase_orders[po].po_confirmation_status === 'Pending'){
          self.pending_purchase_orders_arr.push(purchase_orders[po])
        }
        for(var i in purchase_orders[po].items){
          var item = purchase_orders[po].items[i]
          item.po_number = po
          item.po_date_sortable = purchase_orders[po].po_date_sortable
          item.po_date_formatted = purchase_orders[po].po_date_formatted

          item.eta_sortable = Moment(item.eta).startOf('day')

          var today_formatted = Moment(Date.now()).format('YYYY-MM-DD')
          var eta_formatted = Moment(new Date(item.eta)).format('YYYY-MM-DD')

          item.past_due = Moment(eta_formatted).isBefore(today_formatted)

          // or anything past due.
          if(purchase_orders[po].po_confirmation_status !== 'Complete' && item.line_complete === 'N' && (item.pending_eta_request || item.past_due)){
            self.pending_eta_items.push(item)
          }

          if(purchase_orders[po].po_confirmation_status !== 'Complete' && item.line_complete === 'N' && (item.eta === 'UTP' || item.eta === 'TBD')){
            self.utp_tbd_items.push(item)
          }

        }
      }
    })
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status']),
    vendor_id(){
      return this.vendor_page_id.split('937')[0]
    }
  },
  methods: {
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    openItemInfoPopUp(po,item){
      this.selected_po_for_component = po
      this.selected_item_for_component = item
      this.show_vendor_update_item_info_popup = true
    },
    closeItemInfoPopUp(){
      this.show_vendor_update_item_info_popup = false
    },
    openPurchaseOrderDetailPopUp(po){
      this.selected_po_for_component = po
      this.show_po_detail_popup = true
    },
    closePurchaseOrderDetailPopUp(){
      this.selected_po_for_component = null
      this.show_po_detail_popup = false
    },
    openDisputePurchaseOrderPopUp(po){
      this.selected_po_for_component = po
      this.show_dispute_po_popup = true
    },
    closeDisputePurchaseOrderPopUp(){
      this.selected_po_for_component = null
      this.show_dispute_po_popup = false
    },
    openPurchaseOrderConfirmationPopUp(po){
      this.show_po_confirmation_popup = true
      this.selected_po_for_component = po
    },
    closePurchaseOrderConfirmationPopUp(){
      this.show_po_confirmation_popup = false
      this.selected_po_for_component = null
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
  }
}
</script>

<style scoped>

  .vendor{
    margin: 20px;
  }

  table{
    font-size: 12px;
  }
  th {
    text-align: left;
    padding-right: 20px;
  }
  td {
    text-align: left;
    padding-right: 20px;
  }

  .table-container{
    padding: 15px;
  }

  .table-title{
    padding-top: 15px;
  }
</style>
