<template>
  <div class="customer-ar">
    
    <v-card v-if="customer_info" style="padding:10px 0px;margin-bottom:20px">
      <v-row>
        <v-col cols="6">
          <v-card-subtitle style="font-weight:bold">{{customer_info.customer_name}}</v-card-subtitle>
          <v-card-subtitle>Customer Number: {{customer_info.customer_number}}</v-card-subtitle>
          <v-card-subtitle>Account Executive: {{userInfo(customer_info.salesperson_code)}}</v-card-subtitle>
          <v-card-subtitle>Account Specialist: {{userInfo(customer_info.account_specialist)}}</v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-if="customer_info && ar_info" style="padding:10px;margin-bottom:20px">
      <div style="font-weight:bold;color:gray;font-size:14px">Current</div>
      <v-row style="color:gray;font-size:12px">
        <v-col cols="6">
          <div>Credit Status: {{customer_info.credit_status}}</div>
          <div>Upload Date: {{formatDate(ar_info.upload_date)}}</div>
          <div>Current: {{ar_info.current !== 987654321 ? formatCurrency(ar_info.current)  : 'not in latest AR file'}}</div>
          <div>Open Order Balance: {{ar_info.open_order_balance !== 987654321 ? formatCurrency(ar_info.open_order_balance) : 'not in latest AR file'}}</div>
          <div>A/R Balance: {{ar_info.ar_balance !== 987654321 ? formatCurrency(ar_info.ar_balance) : 'not in latest AR file'}}</div>
        </v-col>
        <v-col cols="6">
          <div>1 - 30: {{formatCurrency(ar_info['1_30'])}}</div>
          <div>31 - 60: {{formatCurrency(ar_info['31_60'])}}</div>
          <div>61 - 90: {{formatCurrency(ar_info['61_90'])}}</div>
          <div>91 & Over: {{formatCurrency(ar_info['91_over'])}}</div>
          <div>31+ Days Past Due Balance: {{formatCurrency(ar_info['31_60'] + ar_info['61_90'] + ar_info['91_over'])}}</div>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-if="customer_info" style="padding:10px">
      <div style="font-weight:bold;color:gray;font-size:14px">History</div>
      <Vue3EasyDataTable
        :headers="headers_ar"
        :items="historic_ar_table_data"
        :table-height="table_height"
        :hide-rows-per-page="true"
        :hide-footer="true"
      >
        <template #item-upload_date="item">
          {{formatDate(item.upload_date)}}
        </template>

        <template #item-ar_balance="item">
          {{formatCurrency(item.ar_balance)}}
        </template>

        <template #item-open_order_balance="item">
          {{formatCurrency(item.open_order_balance)}}
        </template>

        <template #item-credit_limit="item">
          {{formatCurrency(item.credit_limit)}}
        </template>

        <template #item-current="item">
          {{formatCurrency(item.current)}}
        </template>          

        <template #item-1_30="item">
          {{formatCurrency(item['1_30'])}}
        </template>      

        <template #item-31_60="item">
          {{formatCurrency(item['31_60'])}}
        </template>      

        <template #item-61_90="item">
          {{formatCurrency(item['61_90'])}}
        </template>   

        <template #item-91_over="item">
          {{formatCurrency(item['91_over'])}}
        </template>   

        <template #item-past_due_31_more_balance="item">
          {{formatCurrency(item['past_due_31_more_balance'])}}
        </template>                    
      </Vue3EasyDataTable>
    </v-card>

    <v-card v-if="customer_info" style="padding:10px;margin-top:20px">
      <div style="font-weight:bold;color:gray;font-size:14px">Contacts</div>
      <Vue3EasyDataTable
        :headers="headers_contact_historic"
        :items="contacts_table_data"
        :table-height="table_height"
        :hide-rows-per-page="true"
        :hide-footer="true"
      >

        <template #item-timestamp="item">
          {{formatDate(item.timestamp)}}
        </template>

        <template #item-sent_by="item">
          {{db.users[item.sent_by].first_name + ' ' + db.users[item.sent_by].last_name}}
        </template>

        <template #item-past_due_31_more_balance="item">
          {{formatCurrency(item['past_due_31_more_balance'])}}
        </template>                    
      </Vue3EasyDataTable>
    </v-card>
    <span class="version-footer">
      {{$store.state.version}}
    </span>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { debounce } from 'lodash';

export default {
  name: 'CustomerArView',
  components: {Vue3EasyDataTable},
  data(){
    return {
      customer_page_id: this.$route.params['customerarpageid'],
      table_height: 0,
      headers_ar: [
        { text: "Upload Date", value: "upload_date", sortable: false },
        { text: "Credit Status", value: "credit_status", sortable: false },
        { text: "A/R Balance", value: "ar_balance", sortable: false },
        { text: "Current", value: "current", sortable: true },
        { text: "1 - 30", value: "1_30", sortable: false },
        { text: "31 - 60", value: "31_60", sortable: false },
        { text: "61 - 90", value: "61_90", sortable: false },
        { text: "91 & Over", value: "91_over", sortable: false },
        { text: "31+ Days Past Due Balance", value: "past_due_31_more_balance", sortable: false },
      ],
      headers_contact_historic: [
        { text: "Sent Date", value: "timestamp", sortable: false },
        { text: "Sent By", value: "sent_by", sortable: false },
        { text: "31+ Days Past Due Balance", value: "past_due_31_more_balance", sortable: false },
      ],
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){

  },
  computed: {
    ...mapGetters(['user','db']),
    customer_info(){
      return this.db.customers['customer_'+this.customer_page_id]
    },
    ar_info(){
      return this.db.accounts_receivable['customer_'+this.customer_page_id]
    },
    current_ar_table_data(){
      var arr = []
      if(!this.ar_info){
        return []
      }
      return [this.ar_info]
    },
    historic_ar_table_data(){
      var arr = []
      if(!this.ar_info){
        return []
      }
      for(var har in this.ar_info.historic){
        arr.push(this.ar_info.historic[har])
      }
      arr.sort((a, b) => new Date(b.upload_date) - new Date(a.upload_date));
      return arr;
    },
    contacts_table_data(){
      var arr = []
      if(!this.ar_info){
        return []
      }
      for(var har in this.ar_info.reminders_sent){

        arr.push(this.ar_info.reminders_sent[har])
      }
      arr.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      return arr;
    }
  },
  methods: {
    formatDate(date){
      return Moment(date).format('MM/DD/YY hh:mma')
    },
    formatCurrency(value) {
      const absoluteValue = Math.abs(value);
      const sign = value < 0 ? "-" : "";
      const formattedValue = absoluteValue.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return `${sign}$${formattedValue}`;
    },    
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 600
    },
    userInfo(code){
      var info = null
      for(var u in this.db.users){
        if(this.db.users[u].initials === code){
          info = this.db.users[u]
          break;
        }
      }
      return info ? info.first_name + ' ' + info.last_name + ' ' + info.email + '' : ''
    } 
  }
}
</script>

<style scoped>
  .customer-ar{
    margin: 20px;
  }
</style>
