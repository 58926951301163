<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">

      <v-card-text>

          <v-card-title style="margin-left:-15px;color:gray;text-align:center">How are you liking this software?</v-card-title>

          <div style="margin: 15px;text-align:center">
            <v-btn
              :class="thumb === 'down' ? 'selected' : 'unselected'"
              @click="selectThumb('down')"
            >
              <v-icon>mdi-thumb-down</v-icon>
            </v-btn>

            <v-btn
              :class="thumb === 'up' ? 'selected' : 'unselected'"
              @click="selectThumb('up')"
            >
              <v-icon>mdi-thumb-up</v-icon>
            </v-btn>
          </div>

        <v-text-field variant="outlined" placeholder="*Your Email" label="*Your Email" v-model.trim="email" required></v-text-field>
        <v-textarea variant="outlined" height="20" :placeholder="'*Feedback'" label="*Feedback" v-model.trim="note" required></v-textarea>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveNote()" :disabled="!note || !thumb || !email">SEND</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import { emailMixin } from '../mixins/emailMixin.js'

export default {
  name: 'FeedbackPopUp',
  components: {},
  mixins: [emailMixin],
  props:{
    customer: String,
  },
  data(){
    return{
      dialog: true,
      thumb: false,
      email: '',
      note: ''
    }
  },
  beforeMount(){

  },
  computed: {
    ...mapGetters(['user','db']),
  },
  methods:{
    selectThumb(direction) {
      this.thumb = this.thumb !== direction ? direction : false
    }, 
    saveNote(){
      var e = {
        customer: this.customer,
        thumb: this.thumb,
        email: this.email,
        note: this.note
      }
      this.feedbackEmail(e)
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.v-btn + .v-btn {
  margin-left: 10px;
}

.unselected{
  background: white;
  color: #ff7214;
  width: 100px;
  height: 50px;
  font-size: 25px;
}

.selected{
  background: #ff7214;
  color: white;
  width: 100px;
  height: 50px;
  font-size: 25px;
}
</style>


