<template>
  <div class="vendors">
    <div style="font-size: 22px; color: gray;padding:10px">Vendors Master</div>

    <div v-if="vendors_table_data.length">
      <v-row style="width:750px;margin:0px 0px">
        <v-col cols="6">
          <div style="width:350px;">
            <v-text-field label="Search Vendors..." variant="solo" density="comfortable" hide-details="true" @input="debouncedSearch($event)"></v-text-field>
          </div>
        </v-col>
      </v-row>

      <div class="table-container">
          <Vue3EasyDataTable
            :headers="headers"
            :items="vendors_table_data"
            :search-value="searchValue"
            :sort-by="sortBy"
            :sort-type="sortType"
            :table-height="table_height"
            :rows-per-page="250"
            :rows-items="[250, 500, 1000, 5000]"
            :hide-rows-per-page="false"
            :hide-footer="false"
          >

          <template #item-name="item">
            <router-link :to="{ name: 'vendor', params: {vendorpageid: item.page}}" target="_blank" style="color:#ff7214">{{item.name}}</router-link>
          </template>

          <template #item-default_eta_days="item">
            <span style="color:#FF7214;text-decoration:underline;cursor:pointer" @click="openVendorDefaultEtaDays(item.vendor_key)">{{item.default_eta_days}}</span>
          </template>

          <template #item-upload_date_sortable="item">
            {{item.upload_date_formatted}}
          </template>

        </Vue3EasyDataTable>
      </div>
    </div>
    <VendorDefaultEtaPopUp v-if="selected_vendor" @close="closeVendorDefaultEtaDays()" :vendorkey='selected_vendor'/>
  </div>
</template>

<script>

import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { getDatabase, ref, query, orderByChild, equalTo, onChildAdded, onChildChanged, onChildRemoved } from 'firebase/database';
import { debounce } from 'lodash';
import VendorDefaultEtaPopUp from '@/components/VendorDefaultEtaPopUp.vue'

export default {
  name: 'VendorsMaster',
  components: {Vue3EasyDataTable,VendorDefaultEtaPopUp},
  data(){
    return {
      filelist: [],
      searchValue: '',
      sortBy: 'name',
      sortType: 'asc',
      table_height: 400,
      headers: [
        { text: "Vendor Name", value: "name", sortable: true },
        { text: "Vendor Number", value: "number", sortable: true },
        // { text: "Vendor Page", value: "page", sortable: true },
        { text: "Default ETA Days", value: "default_eta_days", sortable: true },
        { text: "Self-Confirm Vendor POs?", value: "self_confirm_po", sortable: true },
        { text: "Self-Track Vendor ETAs?", value: "self_track_eta", sortable: true },
        { text: "PO Confirmation Email", value: "po_confirmation_email", sortable: true },
        { text: "ETA Inquiry Email", value: "eta_inquiry_email", sortable: true },
        { text: "Escalation Email", value: "escalation_email", sortable: true },
        // { text: "Upload Date", value: "upload_date_sortable", sortable: true },
        // { text: "Upload By", value: "upload_by", sortable: true }
      ],
      vendors_table_data: [],
      selected_vendor: false,
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }    
    this.$store.state.isLoading = true
  },
  mounted() {
    setTimeout(() => {
      const db = getDatabase();
      const vendorsRef = ref(db, 'vendors');

      this.onChildAddedListener = onChildAdded(vendorsRef, (snapshot) => {
        const vendor = snapshot.val();
        vendor.id = snapshot.key;
        vendor.upload_date_sortable = Moment(vendor.upload_date).startOf('day')
        vendor.upload_date_formatted = this.formatDate(vendor.upload_date_sortable)
        vendor.upload_by = this.db.users[vendor.upload_by].email
        vendor.self_confirm_po = vendor.self_confirm_po ? 'Y' : ''
        vendor.self_track_eta = vendor.self_track_eta ? 'Y' : ''
        vendor.number = parseInt(vendor.number)
        vendor.vendor_key = vendor.id

        this.vendors_table_data.push(vendor)
        this.$store.state.isLoading = false;
      });

      this.onChildChangedListener = onChildChanged(vendorsRef, (snapshot) => {
        const vendor = snapshot.val();
        vendor.id = snapshot.key;
        vendor.upload_date_sortable = Moment(vendor.upload_date).startOf('day')
        vendor.upload_date_formatted = this.formatDate(vendor.upload_date_sortable)
        vendor.upload_by = this.db.users[vendor.upload_by].email
        vendor.self_confirm_po = vendor.self_confirm_po ? 'Y' : ''
        vendor.self_track_eta = vendor.self_track_eta ? 'Y' : ''
        vendor.number = parseInt(vendor.number)
        vendor.vendor_key = vendor.id
        const index = this.vendors_table_data.findIndex(i => i.id === vendor.id);
        if (index !== -1) {
          this.vendors_table_data.splice(index, 1, vendor);
        }
      });

      this.onChildRemovedListener = onChildRemoved(vendorsRef, (snapshot) => {
        const id = snapshot.key;
        const index = this.vendors_table_data.findIndex((vendor) => vendor.id === id);
        if (index !== -1) {
          this.vendors_table_data.splice(index, 1);
        }
      });

    }, 1000);
  },
  beforeUnmount() {
    if (this.onChildAddedListener) {
      this.onChildAddedListener();
    }
    if (this.onChildChangedListener) {
      this.onChildChangedListener();
    }
    if (this.onChildRemovedListener) {
      this.onChildRemovedListener();
    }
  },
  computed: {
    ...mapGetters(['user','db']),
  },
  methods: {
    openVendorDefaultEtaDays(vendor_key){
      this.selected_vendor = vendor_key
    },
    closeVendorDefaultEtaDays(vendor_key){
      this.selected_vendor = false
    },
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 260
    },
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value
    }, 500),
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .table-container{
    position: relative;
    top: 5px;
    margin:0px 10px;
  }
</style>
