<template>

  <div class="none-current-etas">
    <div style="font-size: 22px; color: gray;padding:10px">Non-Current ETAs</div>
    <div v-if="showTable" style="margin:10px">
      <v-row>
        <v-col cols="12">
          <v-card elevation="12">
            <v-card-title></v-card-title>
            <v-card-text>

              <v-row>
              
                <v-col cols="4">
                  <div>
                    <v-text-field label="Search..." variant="solo" density="compact" hide-details="true" @input="debouncedSearch($event)"></v-text-field>
                  </div>
                </v-col>

                <v-col cols="3">
                  <v-select
                    :items="specialists_arr"
                    label="Account Specialist"
                    v-model="$store.state.selectedSpecialist"
                    density="compact"
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="5">
                  <v-row class="checkbox-row">

                    <v-col cols="4">
                      <v-checkbox @input="debouncedUpdateFilter" v-model='status_filter_ui' class="custom-checkbox" label="Due Now" value="Due Now" color="#ff7214" hide-details="auto"></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox @input="debouncedUpdateFilter" v-model='status_filter_ui' class="custom-checkbox" label="Due Soon" value="Due Soon" color="#ff7214" hide-details="auto"></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox @input="debouncedUpdateFilter" v-model='status_filter_ui' class="custom-checkbox" label="Complete" value="Complete" color="#ff7214" hide-details="auto"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div>* = Unlinked PO</div>
              <Vue3EasyDataTable
                :headers="headers"
                :items="customer_backorders"
                :search-value="searchValue"
                :sort-by="sortBy"
                :sort-type="sortType"
                :hide-footer="false"
                :rows-per-page="250"
                :rows-items="[250, 500, 1000, 5000]"
                :hide-rows-per-page="true"
                :table-height="table_height"
              >

                <template #item-critical_item="item">
                  <div class="critical_btn" @click="flagCritical(item.backorder_key)" v-if="!item.critical_item"></div>
                  <div class="critical_btn_active" @click="unflagCritical(item.backorder_key)" v-if="item.critical_item"></div>
                </template>

                <template #item-vendor="item">
                  <router-link v-if="item.vendor_page" :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page}}" target="_blank" style="color:#ff7214;font-weight:bold">{{item.vendor}}</router-link>
                  <span v-if="!item.vendor_page">{{item.vendor}}</span>
                </template>

                <template #item-customer_name="item">
                  <router-link :to="{ name: 'customer', params: {customerpageid: item.customer_page}}" target="_blank" style="color:#ff7214;font-weight:bold">{{item.customer_name}}</router-link>
                </template>
                
                <template #item-linked_po_number="item">
                  <span v-if="item.po_for_detail" style='color:#ff7214;cursor:pointer;font-weight:bold' @click="openPurchaseOrderDetailPopup(item.po_for_detail)">{{item.linked_po_number}}</span>
                  <span v-if="!item.po_for_detail">{{item.linked_po_number}}</span>
                </template>

                <template #item-order_date_sortable="item">
                  {{item.order_date}}
                </template>

                <template #item-internal_notes="item">
                  <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
                  </div>
                  <v-btn v-if="user !== 'vendor'" style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="backorderNote('internal',item.id)">ADD NOTE</v-btn>
                </template>

                <template #item-inquiries="item">
                  <div v-for="n in item.inquiries" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
                  </div>
                  <v-btn v-if="user !== 'vendor'" style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="backorderNote('shared',item.id)">ADD NOTE</v-btn>
                </template>

                <template #item-communication_eta_status="item">
                  <div :class="statusClass(item.communication_eta_status)">
                    {{ item.communication_eta_status }}
                  </div>
                </template>

              </Vue3EasyDataTable>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <PurchaseOrderDetailPopUp v-if="po_for_component" :po='po_for_component' @close="closePurchaseOrderDetailPopup()" />
    <BackorderNotePopUp v-if="backorder_note_popup" @close="backorder_note_popup = false" :backorderkey="backorder_key_for_component" :type="backorder_note_type"/>

  </div>

</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { debounce } from 'lodash';
import PurchaseOrderDetailPopUp from '@/components/PurchaseOrderDetailPopUp.vue'
import BackorderNotePopUp from '@/components/BackorderNotePopUp.vue'

export default {
  name: 'NonCurrentEtas',
  components: {Vue3EasyDataTable,PurchaseOrderDetailPopUp,BackorderNotePopUp},
  data(){
    return {
      backorder_note_popup: false,
      backorder_note_type: null,
      table_height: 350,
      searchValue: '',
      sortBy: 'customer_name',
      sortType: 'desc',
      backorder_item_inquiry_key: false,
      showTable: false,
      po_for_component: false,
      status_filter_ui: ['Due Now'],
      status_filter: ['Due Now'],
      specialists_arr: ['All'],
      headers: [
          //{ text: "Backorder Key", value: "id", sortable: true },
          { text: "Critical Item", value: "critical_item", sortable: true },
          { text: "Mfg Name", value: "vendor", sortable: true },
          { text: "Mfg Part #", value: "manufacturer_part_number", sortable: true },
          { text: "Customer Name", value: "customer_name", sortable: true },
          { text: "Account Specialist", value: "account_specialist", sortable: true },
          { text: "Sales Order Number", value: "order_number", sortable: true },
          { text: "Order Date", value: "order_date_sortable", sortable: true },
          { text: "Product Number", value: "product_number", sortable: true },
          { text: "Riverhawk PO Date", value: "linked_po_date", sortable: false },
          { text: "Riverhawk PO Number", value: "linked_po_number", sortable: true },
          { text: "ETA", value: "eta", sortable: true },
          { text: "Internal Notes", value: "internal_notes", sortable: false },
          { text: "Shared Notes", value: "inquiries", sortable: false },          
          { text: "Communication ETA Status", value: "communication_eta_status", sortable: true },
        ]
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  watch: {
    status_filter_ui(){
      this.$store.state.isLoading = true
    }
  },
  mounted(){
    this.debouncedUpdateFilter = debounce(this.updateFilter, 300);
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }    
    var self = this
    self.$store.state.isLoading = true
    setTimeout(() => {
      self.showTable = true;
      self.$store.state.isLoading = false
    }, 2000);
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status','item_eta_status','selectedSpecialist']),
    lastUploadDate(){
      return Moment(this.db.last_backorder_file_upload).format('MM/DD/YY hh:mma')
    },
    customer_backorders(){
      var customers = this.db.customers
      var ar_accounts = []
      var date_now = Moment()
      var status_mapping = {}
      for(var cid in customers){
        var customer = customers[cid]
        if(customer.has_dedicated_eta_manager === 'Yes, Account Specialist' && customer.master_ar_account === customer.customer_number){
          var customer_ar_accounts = customer.child_ar_accounts ? customer.child_ar_accounts : []

          var last_eta_update_date = customer.last_eta_update ? Moment(customer.last_eta_update) : false
          var provide_eta_updates_every_x_days = parseFloat(customer.send_eta_update_every_x_days)
          if(last_eta_update_date){
            var days_difference = date_now.diff(last_eta_update_date, 'days')
            if (days_difference >= provide_eta_updates_every_x_days) {
              customer.eta_update_status = 'Due Now';
            } else if (provide_eta_updates_every_x_days - days_difference <= 3) {
              customer.eta_update_status = 'Due Soon';
            } else if (days_difference < provide_eta_updates_every_x_days - 3) {
              customer.eta_update_status = 'Complete';
            }
          }else{
            customer.eta_update_status = 'Due Now'
          }
          status_mapping[customer.customer_number] = customer.eta_update_status
          
          if(this.specialists_arr.indexOf(customer.account_specialist) === -1){
            this.specialists_arr.push(customer.account_specialist)
          }

          customer_ar_accounts.push(customer.master_ar_account)
          for(var i = 0; i < customer_ar_accounts.length; i++){
            if(ar_accounts.indexOf(customer_ar_accounts[i]) === -1){
              ar_accounts.push(customer_ar_accounts[i])
            }
          }
        }

      }

      var backorders_db = JSON.parse(JSON.stringify( this.db.backorders))
      var backorders = []

      for(var b in backorders_db){
        var backorder = backorders_db[b]
        backorder.id = b
        if(!backorder.completed && ar_accounts.indexOf(backorder.customer_number) !== -1){
          backorder.dropship = backorder.line_item_warehouse === '09' ? 'YES' : 'NO'
          backorder.critical_item = backorder.critical_item ? true : false
          backorder.customer_page = this.db.customers['customer_'+backorder.customer_number].page
          
          var master_account_number = this.db.customers['customer_'+backorder.customer_number].master_ar_account
          var master_account_info = this.db.customers['customer_'+master_account_number]
          backorder.account_specialist = master_account_info.account_specialist

          backorder.communication_eta_status = status_mapping[master_account_number]

          if(this.db.purchase_orders[backorder.linked_po_number] ){
            backorder.po_for_detail = backorder.linked_po_number
            backorder.vendor = this.db.purchase_orders[backorder.linked_po_number].vendor_name
            backorder.vendor_number = this.db.purchase_orders[backorder.linked_po_number].vendor_number
            backorder.vendor_page = this.db.vendors['vendor_'+backorder.vendor_number].page
            backorder.eta = 'Item Not Found In Nest PO'
            backorder.has_linked_po = true
            backorder.non_current_eta = false
            backorder.linked_po_date = this.db.purchase_orders[backorder.linked_po_number].po_date

            for(var item_key in this.db.purchase_orders[backorder.linked_po_number].items){
              if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].product === backorder.product_number){
                backorder.eta = this.db.purchase_orders[backorder.linked_po_number].items[item_key].eta
                backorder.po_says_complete = this.db.purchase_orders[backorder.linked_po_number].items[item_key].line_complete === 'Y' ? true : false

                backorder.manufacturer_part_number = this.db.purchase_orders[backorder.linked_po_number].items[item_key].manufacturer_part_number

                if(backorder.eta === 'UTP'){
                  backorder.eta = 'Vendor Unable To Provide Date'
                }

                if(backorder.eta === 'TBD'){
                  backorder.eta = 'ETA TBD'
                }

                if(backorder.eta === ''){
                  backorder.eta = this.item_eta_status(this.db.purchase_orders[backorder.linked_po_number],item_key)
                }
              }
            }
          }else{
            backorder.has_linked_po = false
            var potential_etas = []
            for(var po in this.db.purchase_orders){

              if (/[a-zA-Z]/.test(po)) { //make sure PO is not a special order with a letter
                continue;
              }

              for(var item in this.db.purchase_orders[po].items){
                if(this.db.purchase_orders[po].items[item].product === backorder.product_number && this.db.purchase_orders[po].items[item].line_complete !== 'Y'){
                  potential_etas.push(this.db.purchase_orders[po].items[item].eta + '|' + this.db.purchase_orders[po].po_number + '|'  + this.db.purchase_orders[po].vendor_name + '|'  + this.db.purchase_orders[po].vendor_number + '|'  + this.db.vendors['vendor_'+this.db.purchase_orders[po].vendor_number].page + '|' + this.db.purchase_orders[po].items[item].manufacturer_part_number)
                }
              }
            }

            var hasEmptyString = false;
            var hasUTPValue = false;
            var hasTBDValue = false;
            var earliestDate = null;
            var po;
            var vendor;
            var vendor_number;
            var vendor_page;
            var po_for_detail;
            var manufacturer_part_number;

            for (var i = 0; i < potential_etas.length; i++) {
              const eta = potential_etas[i].split('|')[0];
              const p = potential_etas[i].split('|')[1];
              const v = potential_etas[i].split('|')[2];
              const v_number = potential_etas[i].split('|')[3];
              const v_page = potential_etas[i].split('|')[4];
              const mpn = potential_etas[i].split('|')[5];

              if (eta === '') {
                hasEmptyString = true;
                po = p
                vendor = v
                vendor_number = v_number
                vendor_page = v_page
                po_for_detail = po
                manufacturer_part_number = mpn
                continue;
              }

              if (eta === 'UTP') {
                hasUTPValue = true;
                po = p
                vendor = v
                vendor_number = v_number
                vendor_page = v_page
                po_for_detail = po
                manufacturer_part_number = mpn
                continue;
              }

              if (eta === 'TBD') {
                hasTBDValue = true;
                po = p
                vendor = v
                vendor_number = v_number
                vendor_page = v_page
                po_for_detail = po
                manufacturer_part_number = mpn
                continue;
              }

              const date = Moment(eta, 'M/DD/YY');

              if (earliestDate === null || date.isBefore(earliestDate, 'day')) {
                earliestDate = date;
                po = p
                vendor = v
                vendor_number = v_number
                vendor_page = v_page
                po_for_detail = po
                manufacturer_part_number = mpn
              }
            }

            if (earliestDate === null) {
              if (hasUTPValue) {
                backorder.eta = 'Vendor Unable To Provide Date';
                backorder.linked_po_number = po + ' *'
                backorder.vendor = vendor + ' *'
                backorder.vendor_number = vendor_number
                backorder.vendor_page = vendor_page
                backorder.po_for_detail = po_for_detail
                backorder.manufacturer_part_number = manufacturer_part_number
                backorder.linked_po_date = this.db.purchase_orders[po_for_detail].po_date + ' *'
              } else if (hasTBDValue) {
                backorder.eta = 'ETA TBD';
                backorder.linked_po_number = po + ' *'
                backorder.vendor = vendor + ' *'
                backorder.vendor_number = vendor_number
                backorder.vendor_page = vendor_page
                backorder.po_for_detail = po_for_detail
                backorder.manufacturer_part_number = manufacturer_part_number
                backorder.linked_po_date = this.db.purchase_orders[po_for_detail].po_date + ' *'
              } else if (hasEmptyString) {
                backorder.eta = 'Pending ETA From Vendor';
                backorder.linked_po_number = po + ' *'
                backorder.vendor = vendor + ' *'
                backorder.vendor_number = vendor_number
                backorder.vendor_page = vendor_page
                backorder.po_for_detail = po_for_detail
                backorder.manufacturer_part_number = manufacturer_part_number
                backorder.linked_po_date = this.db.purchase_orders[po_for_detail].po_date + ' *'
              } else {
                backorder.eta = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending'
                backorder.linked_po_number = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending'
                backorder.vendor = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending'
                backorder.vendor_number = false
                backorder.vendor_page = false
                backorder.po_for_detail = false
                backorder.linked_po_date = 'No PO Found'
              }
              backorder.non_current_eta = true
            } else {
                backorder.eta = earliestDate.format('M/DD/YY');
                backorder.linked_po_number = po + ' *'
                backorder.vendor = vendor + ' *'
                backorder.vendor_number = vendor_number
                backorder.vendor_page = vendor_page
                backorder.po_for_detail = po_for_detail
                backorder.manufacturer_part_number = manufacturer_part_number
                backorder.linked_po_date = this.db.purchase_orders[po_for_detail].po_date + ' *'
            }
          }

          if (Moment(backorder.eta, 'M/DD/YY').valueOf() < Moment().startOf('day').valueOf() && !backorder.po_says_complete) {
            backorder.eta = '(' + backorder.eta + ') Past Due, Update Pending';
            backorder.non_current_eta = true
          }

          if(backorder.eta == 'PO Conf Pending' || backorder.eta == 'Vendor Unable To Provide Date' || backorder.eta == 'ETA TBD' || backorder.eta == 'Not Requested' || backorder.eta == 'PO Disputed'  && !backorder.po_says_complete){
            backorder.non_current_eta = true
          }

          if(!backorder.has_linked_po && this.user !== 'vendor'){
            backorder.eta = backorder.eta + ' *'
          }

          backorder.order_date_sortable = Moment(backorder.order_date).valueOf()

          if(backorder.non_current_eta && (this.selectedSpecialist === 'All' || this.selectedSpecialist === backorder.account_specialist) && (this.status_filter.indexOf(backorder.communication_eta_status) !== -1) ){
            backorder.manufacturer_part_number = backorder.manufacturer_part_number ? backorder.manufacturer_part_number : ''
            backorders.push(backorder)  
          }
          
        }
      }
      return backorders
    }
  },
  methods: {
    updateFilter(value) {
      this.status_filter = JSON.parse(JSON.stringify( this.status_filter_ui))
      this.$store.state.isLoading = false
    },    
    statusClass(status) {
      switch (status) {
        case 'Complete':
          return 'status-complete';
        case 'Due Soon':
          return 'status-due-soon';
        case 'Due Now':
          return 'status-due-now';
        default:
          return '';
      }
    },    
    backorderNote(type,id){
      this.backorder_note_type = type
      this.backorder_key_for_component = id
      this.backorder_note_popup = true
    },
    salesPersonInfo(code){
      var info = null
      for(var u in this.db.users){
        if(this.db.users[u].initials === code){
          info = this.db.users[u]
          break;
        }
      }
      return info ? info.first_name + ' ' + info.last_name + ' ' + info.email + '' : ''
    },
    accountSpecialistInfo(code){
      var info = null
      for(var u in this.db.users){
        if(this.db.users[u].initials === code){
          info = this.db.users[u]
          break;
        }
      }
      return info ? info.first_name + ' ' + info.last_name + ' ' + info.email + '' : ''
    },
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 300
    },
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value.replace(/\*/g, "");
    }, 500),
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    flagCritical(item){
      var self = this
      var confirm_flag = confirm('Are you sure you want to flag ' + this.db.backorders[item].product_number + '?')
      if(confirm_flag){
        firebase.database().ref('backorders').child(item).child('critical_history').push({'set_to': true, 'set_by': (self.user !== 'vendor' ? self.user : 'customer'), 'set_date': Date.now()})
        firebase.database().ref('backorders').child(item).child('critical_item').set(true)
      }
    },
    unflagCritical(item){
      var self = this
      var confirm_unflag = confirm('Are you sure you want to unflag ' + this.db.backorders[item].product_number + '?')
      if(confirm_unflag){
        firebase.database().ref('backorders').child(item).child('critical_history').push({'set_to': false, 'set_by': (self.user !== 'vendor' ? self.user : 'customer'), 'set_date': Date.now()})
        firebase.database().ref('backorders').child(item).child('critical_item').set(false)
      }
    },
    openPurchaseOrderDetailPopup(po){
      this.po_for_component = po
    },
    closePurchaseOrderDetailPopup(){
      this.po_for_component = false
    },
  }
}
</script>

<style scoped>

  .vendor{
    margin: 20px;
  }

  table{
    font-size: 12px;
  }
  th {
    text-align: left;
    padding-right: 20px;
  }
  td {
    text-align: left;
    padding-right: 20px;
  }

  .table-container{
    padding: 15px;
  }

  .table-title{
    padding-top: 15px;
  }

  .critical_btn{
    border: 1px solid #ff7214;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .critical_btn_active{
    background: #ff7214;
    border: 1px solid #ff7214;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
  }

  .critical_btn_active::after{
      content: "✔";
  }

  .riverhawk-header{
    background: #ff7214;
    height: 40px;
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
  }

  .riverhawk-header-text{
    color: white;
    font-weight: bold;
    padding: 5px;
  }

  .checkbox-row {
    width: 375px;
    float: right;
  }

  >>>.custom-checkbox .v-label {
    font-size: 0.8rem;
  }

  ::v-deep .v-select__selections {
    height: 50px !important;
    min-height: 50px !important;
  }

.status-complete {
    color: green;
    width: 70px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
}

.status-due-soon {
    background-color: #FFA500;
    color: white;
    width: 70px;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    font-size: 12px;
}

.status-due-now {
    background-color: red;
    color: white;
    width: 70px;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    font-size: 12px;
}  
</style>
