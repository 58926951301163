<template>
  <div class="users-administration">
    <div style="font-size: 22px; color: gray;padding:10px">Analytics</div>
    <div style="margin:0px 10px">

    Customers
      <Vue3EasyDataTable
        :headers="customer_headers"
        :items="analytics_table_data_customers"
        :sort-by="sortBy"
        :sort-type="sortType"
        :rows-per-page="2000"
        :hide-rows-per-page="true"
        :hide-footer="true"
        :table-height="table_height"
      >
        <template #item-date_of_last_visit="item">
          {{formatDateTime(item.date_of_last_visit)}}
        </template>
      </Vue3EasyDataTable>

      <div style="margin-top:25px">
    Vendors
      <Vue3EasyDataTable
        :headers="vendor_headers"
        :items="analytics_table_data_vendors"
        :sort-by="sortBy"
        :sort-type="sortType"
        :rows-per-page="2000"
        :hide-rows-per-page="true"
        :hide-footer="true"
        :table-height="table_height"
      >
        <template #item-date_of_last_visit="item">
          {{formatDateTime(item.date_of_last_visit)}}
        </template>
      </Vue3EasyDataTable>
      </div>
    </div>

  </div>
</template>

<script>

import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Vue3EasyDataTable from 'vue3-easy-data-table';

export default {
  name: 'AnalyticsAdministration',
  components: {Vue3EasyDataTable},
  data(){
    return {
      add_user: false,
      edit_user: false,
      edit_id: '',
      include_deactived_users: false,
      sortBy: 'date_of_last_visit',
      sortType: 'desc',
      table_height: 400,
      customer_headers: [
        { text: "Customer Name", value: "customer_name", sortable: true },
        { text: "Customer Number", value: "customer_number", sortable: true },
        { text: "Last Visit Date", value: "date_of_last_visit", sortable: true },
        { text: "Number Of Visits", value: "number_of_visits", sortable: true },        
      ],
      vendor_headers: [
        { text: "Vendor Name", value: "vendor_name", sortable: true },
        { text: "Vendor Number", value: "vendor_number", sortable: true },
        { text: "Last Visit Date", value: "date_of_last_visit", sortable: true },
        { text: "Number Of Visits", value: "number_of_visits", sortable: true },        
      ]      
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    analytics_table_data_customers() {
      var customers = {}; // Initialize an object to store customer information
      var analytics = JSON.parse(JSON.stringify(this.db.analytics));
      for (var a in analytics) {
        var analytic = analytics[a];
        if (analytic.type === 'external' && analytic.page === 'customer') {
          analytic.customer_number = analytic.customer_id.split('_')[1];
          if (customers[analytic.customer_number]) {
            customers[analytic.customer_number].number_of_visits++;
            if (analytic.date > customers[analytic.customer_number].date_of_last_visit) {
              customers[analytic.customer_number].date_of_last_visit = analytic.date;
            }
          } else {
            customers[analytic.customer_number] = {
              customer_name: analytic.customer_name,
              customer_number: analytic.customer_number,
              number_of_visits: 1,
              date_of_last_visit: analytic.date,
            };
          }
        }
      }
      var arr = Object.values(customers);
      return arr;
    },
analytics_table_data_vendors() {
  var vendors = {}; // Initialize an object to store vendor information
  var analytics = JSON.parse(JSON.stringify(this.db.analytics));
  
  for (var a in analytics) {
    var analytic = analytics[a];
    
    if (analytic.type === 'external' && analytic.page === 'vendor') {
      analytic.vendor_number = analytic.vendor_id.split('_')[1];

      // If the vendor exists in the 'vendors' object, update their information.
      if (vendors[analytic.vendor_number]) {
        vendors[analytic.vendor_number].number_of_visits++;
        if (analytic.date > vendors[analytic.vendor_number].date_of_last_visit) {
          vendors[analytic.vendor_number].date_of_last_visit = analytic.date;
        }
      } else {
        // If the vendor does not exist, initialize their information.
        vendors[analytic.vendor_number] = {
          vendor_name: analytic.vendor_name,
          vendor_number: analytic.vendor_number,
          number_of_visits: 1,
          date_of_last_visit: analytic.date,
          /* Include other properties you want to copy from the 'analytic' object */
        };
      }
    }
  }
  
  // Convert the 'vendors' object to an array of vendor objects
  var arr = Object.values(vendors);
  
  return arr;
}

    // analytics_table_data_vendors(){
    //   var arr = []
    //   var analytics = JSON.parse(JSON.stringify( this.db.analytics))
    //   for(var a in analytics){
    //     var analytic = analytics[a]
    //     if(analytic.type === 'external' && analytic.page === 'vendor'){
    //       analytic.vendor_number = analytic.vendor_id.split('_')[1]
    //       arr.push(analytic)  
    //     }
    //   }
    //   return arr
    // }
  },
  methods: {
    formatDateTime(date){
      return Moment(date).format('MM/DD/YY hh:mma')
    },    
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 475
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .inactive{
    color: #C0C0C0;
  }
</style>
