<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="100vw" min-height="95vh" style="background:lightgray">

      <v-card-text>

        <v-card class="pa-2 ma-2" outlined tile>
          <v-row>
            <v-col cols="4" class="cell">
              <v-btn size="x-small" style="background:#ff7214;color:white" @click="flagCritical(po,itemkey)" v-if="!item.flag_critical">Flag Critical</v-btn>
              <v-btn size="x-small" style="background:#ff7214;color:white" @click="unflagCritical(po,itemkey)" v-if="item.flag_critical">Unflag Critical</v-btn>
            </v-col>

            <v-col cols="4" class="cell">
              <div v-if="item.flag_critical" style="color:red;font-weight:bold;text-decoration:underline;font-size:18px">THIS ITEM HAS BEEN FLAGGED AS CRITICAL</div>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="pa-2 ma-2" outlined tile>
          <v-row>
            <v-col cols="4" class="cell">
              <span class='label'>Manufacturer Part Number:</span>
              {{item.manufacturer_part_number}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Vendor Part Number:</span>
              {{item.vendor_part_number}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Riverhawk Part Number:</span>
              {{item.product}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Product Description:</span>
              {{item.product_description}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Vendor:</span>
              {{db.purchase_orders[po].vendor_name}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Purchase Order:</span>
              {{po}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Purchase Order Date:</span>
              {{item.po_date}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>PO Line Number</span>
              {{item.line_number}}
            </v-col>

            <v-col cols="4" class="cell">
              <span class='label'>Line Complete</span>
              {{item.line_complete}}
            </v-col>

            <v-col cols="4" class="cell">
              <span class='label'>Unit Price</span>
              {{item.unit_price}}
            </v-col>

            <v-col cols="4" class="cell">
              <span class='label'>UOM</span>
              {{item.uom}}
            </v-col>

            <v-col cols="4" class="cell">
              <span class='label'>PO Qty</span>
              {{item.po_quantity}}
            </v-col>

            <v-col cols="4" class="cell">
              <span class='label'>Qty Received</span>
              {{item.qty_rcv}}
            </v-col>

            <v-col cols="4" class="cell">
              <span class='label'>Qty Open</span>
              {{item.qty_open}}
            </v-col>
       
          </v-row>
        </v-card>

        <v-card class="pa-2 ma-2" outlined tile>
          <v-row>
            <v-col cols="4" class="cell">
              <span class="line-header">Item ETA Status:</span>
              {{item.eta_status}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Pending ETA Request:</span>
              {{item.pending_eta_request ? 'YES' : 'NO'}}
            </v-col>

            <v-col cols="4" class="cell">
              <span class='label'>ETA Last Requested:</span>
              {{item.eta_last_requested ? formatDate(item.eta_last_requested) : ''}}
            </v-col>

            <v-col cols="4" class="cell">
              <span class='label'>ETA Last Provided:</span>
              {{item.eta_last_provided ? formatDate(item.eta_last_provided) : ''}}
            </v-col>

            <v-col cols="4" class="cell">
              <span class='label'>ETA Requests Cnt:</span>
              {{item.eta_escalation_requests_cnt}}
            </v-col>

            <v-col cols="12" class="cell">
              <span class='label'>ETA Escalation Requests:</span>
              {{item.eta_escalation_requests}}
            </v-col>
          </v-row>
        </v-card>

        <v-card class="pa-2 ma-2" outlined tile>
          <v-row>
            <v-col cols="4" class="cell">
              <span class="line-header">ETA:</span>
              {{item.eta ? item.eta : 'Not Provided'}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Full Lead Time Days:</span>
              {{item.full_lead_time_days}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Remaining Lead Time Days:</span>
              {{item.remaining_lead_time_days}}
            </v-col>
          </v-row>
          <v-btn size="x-small" style="background:#ff7214;color:white" @click="updateEta()">Update ETA</v-btn>
          <v-btn style="background:#ff7214;color:white;margin-left:25px" size="x-small" @click="requestETA(po,itemkey)" v-if="item.manually_request_eta_allowed">REQUEST ETA UPDATE</v-btn>
        </v-card>

        <v-card class="pa-2 ma-2" outlined tile>
          <v-row>
            <v-col cols="4" class="cell">
              <span class='label'>Shipping Company:</span>
              {{item.shipping_company}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Tracking Number:</span>
              {{item.tracking_number}}
            </v-col>
            <v-col cols="4" class="cell">
              <span class='label'>Drop Ship:</span>
              {{item.drop_ship ? 'YES' : 'NO'}}
            </v-col>                 
          </v-row>
          <v-btn size="x-small" style="background:#ff7214;color:white" @click="updateShippingInfo()">Update Shipping Info</v-btn>
        </v-card>

        <v-card class="pa-2 ma-2" outlined tile>
          <v-row>
            <v-col cols="6" class="cell">
              <div class="line-header">Shared Notes <v-btn size="x-small" style="background:#ff7214;color:white" @click="addNote('shared')">Add Note</v-btn></div>
              <div v-for="n in item.shared_notes" class="note-container">
                <div class="note">{{n.note}}</div>
                <div class="note-detail">submitted {{formatDateTime(n.submitted_date)}} by {{n.submitted_by}}</div>
              </div>
            </v-col>
            <v-col cols="6" class="cell">
              <div class="line-header">Internal Notes <v-btn size="x-small" style="background:#ff7214;color:white" @click="addNote('internal')">Add Note</v-btn></div>
              <div v-for="n in item.internal_notes" class="note-container">
                <div class="note">{{n.note}}</div>
                <div class="note-detail">submitted {{formatDateTime(n.submitted_date)}} by {{n.submitted_by}}</div>
              </div>
            </v-col>
          </v-row>
        </v-card>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:#ff7214;color:white" @click="$emit('close')">CLOSE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
  <NoteItemPopUp v-if="note_popup" @close="closeNotePopUp()" :po="po" :itemkey='itemkey' :type="note_type"/>
  <UpdateShippingInfoPopUp v-if="shipping_info_popup" @close="closeShippingInfoPopUp()" :po="po" :itemkey='itemkey'/>

  <UpdateEtaPopUp v-if="item_eta_popup" @close="closeUpdateEtaPopUp()" :po="po" :itemkey='itemkey'/>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import { emailMixin } from '../mixins/emailMixin.js'
import Datepicker from 'vuejs3-datepicker';
import NoteItemPopUp from '@/components/NoteItemPopUp.vue'
import UpdateShippingInfoPopUp from '@/components/UpdateShippingInfoPopUp.vue'
import UpdateEtaPopUp from '@/components/UpdateEtaPopUp.vue'

export default {
  name: 'ItemDetailPopUp',
  components: {Datepicker,NoteItemPopUp,UpdateShippingInfoPopUp,UpdateEtaPopUp},
  mixins: [emailMixin],
  emits: ["close"],
  props:{
    po: String,
    itemkey: String
  },
  data(){
    return{
      dialog: true,
      item: null,
      note_type: null,
      note_popup: false,
      item_eta_popup: false,
      shipping_info_popup: false
    }
  },
  beforeMount(){
    var self = this
    firebase.database().ref('purchase_orders').child(self.po).on('value', function(purchase_order_snapshot) {
      var purchase_order = purchase_order_snapshot.val()
      var item = JSON.parse(JSON.stringify( purchase_order.items[self.itemkey]))
      item.po_date = purchase_order.po_date
      item.eta_status = self.item_eta_status(purchase_order,item.item_key)
      
      item.manually_request_eta_allowed = ['Past Due', 'On Track', 'Unable To Provide'].indexOf(item.eta_status) !== -1

      var date_a = Moment(item.po_date)
      var date_b = Moment(item.eta)
      var date_c = Moment().startOf('day')

      item.full_lead_time_days = item.eta !== '' && item.eta !== 'UTP' && item.eta !== 'TBD' ? date_b.diff(date_a, 'days') : ''
      item.remaining_lead_time_days = item.eta !== '' && item.eta !== 'UTP' && item.eta !== 'TBD' ? date_b.diff(date_c, 'days') : ''
      self.item = item
    })
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status','item_eta_status']),
  },
  methods:{
    flagCritical(po_number,item_key){
      firebase.database().ref('purchase_orders').child(po_number).child('items').child(item_key).child('flag_critical').set(true)
    },
    unflagCritical(po_number,item_key){
      var confirm_unflag = confirm('Are you sure you want to unflag this item?')
      if(confirm_unflag){
        firebase.database().ref('purchase_orders').child(po_number).child('items').child(item_key).child('flag_critical').set(false)
      }
    },
    requestETA(po_number,item_key){
      var self = this
      var purchase_order = this.db.purchase_orders[po_number]
      var item = purchase_order.items[item_key]
      var vendor_info = this.db.vendors['vendor_'+purchase_order.vendor_number]
      var vendor_name = vendor_info.name
      var vendor_page = vendor_info.page
      var str = 'Send an Escalation ETA Request email to ' + vendor_name + ' for item ' + item.manufacturer_part_number + '?'
      var confirm_eta_request = confirm(str)
      var attempt = item.eta_escalation_requests_cnt + 1

      if(confirm_eta_request){
        var vendor_eta_inquiry_email = vendor_info.eta_inquiry_email
        var vendor_escalation_email = vendor_info.escalation_email
        var send_to_string = vendor_eta_inquiry_email+';'+vendor_escalation_email
        var email = {
          to: send_to_string,
          po: po_number,
          vendor: vendor_name,
          vendor_page: vendor_page,
          item_keys: [item_key],
          requested_by: self.user,
          attempt: attempt
        }
        self.etaRequestEmail(email)
      }
    },
    formatDateTime(date){
      return Moment(date).format('MM/DD/YY HH:mm:a')
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    closeNotePopUp(){
      this.note_popup = false
    },
    closeShippingInfoPopUp(){
      this.shipping_info_popup = false
    },
    addNote(type){
      this.note_type = type
      this.note_popup = true
    },
    closeUpdateEtaPopUp(){
      this.item_eta_popup = false
    },
    updateEta(){
      this.item_eta_popup = true
    },
    updateShippingInfo(){
      this.shipping_info_popup = true
    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .vuejs3-datepicker__icon{
    padding-top: 2px;
  }
  .vuejs3-datepicker__value{
    padding: 5px 15px;
  }
  .vuejs3-datepicker__calendar{

  }
  .vuejs3-datepicker__calendar-topbar{
    display: none;
  }
  .label{
    font-weight: bold;
  }
  .cell{

  }
</style>


