import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

loadFonts()

firebase.initializeApp(
  // {
  //   apiKey: "AIzaSyBapgGRCGX9hKbWZGtEOn06ajbymytajvw",
  //   authDomain: "riverhawk-nest-development.firebaseapp.com",
  //   databaseURL: "https://riverhawk-nest-development-default-rtdb.firebaseio.com/"
  // }
  {
    apiKey: "AIzaSyDj-VNMQ7_j74lHPRU3-om0v9OZecf8RoE",
    authDomain: "riverhawk-nest-production.firebaseapp.com",
    databaseURL: "https://riverhawk-nest-production-default-rtdb.firebaseio.com"
  }
);

firebase.auth().onAuthStateChanged((user) => {
  if(user) {
    localStorage.setItem('isNestUser', true);
    localStorage.setItem('nestUser', user.uid);
  } else {
    localStorage.setItem('isNestUser', false);
    localStorage.removeItem('nestUser');
  }
});

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
