<template>
  <div class="customers-import">

    <div class="dropzone bg-white" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input type="file" name="fields[assetsFieldHandle_c][]" id="assetsFieldHandle_c" @change="onChange" ref="file" style="opacity: 0;display:none"/>
      <div v-if="!filelist.length" style="padding-top:10px;padding-left:25px;color:gray;display:flex;align-items:center;">
        <v-icon class="mdi mdi-file-upload-outline orange--text"></v-icon>
        <span style="padding-left:10px;">Customers File Drop</span>
      </div>
    </div>
    <div style="color:red;font-size:10px;font-weight:bold;padding-top:5px;color:white">.</div>
    <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;position:relative;">
      <v-btn style="background:#ff7214;color:white;height:15px;width:75px;font-size:8px;margin-right:10px;" @click="pullData()">PULL DATA</v-btn>
      <div>
        <div style="font-size:12px;color:gray;font-style:italic;">Last Imported File Date: {{lastUploadDate}}</div>
        <div style="font-size:12px;color:gray;font-style:italic;">Last Pull Data Date: {{lastPullDate}}</div>
      </div>
    </div>
  </div>
</template>

<script>

// import * as XLSX from "xlsx"
import Papa from "papaparse"
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import AWS from 'aws-sdk';

export default {
  name: 'CustomersImport',
  components: {},
  data(){
    return {
      filelist: [],
    }
  },
  mounted() {

  },
  watch: {
    filelist(val){
      if(val.length > 0){
        this.readFiles()
      }
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    lastUploadDate(){
      return Moment(this.db.last_customer_file_upload).format('MM/DD/YY hh:mma')
    },
    lastPullDate(){
      return this.db.last_customer_file_pull ? Moment(this.db.last_customer_file_pull).format('MM/DD/YY hh:mma') : false
    }
  },
  methods: {
    async pullData(){
      AWS.config.region = 'us-east-1';

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:44b121e3-3117-4e3a-b0b1-a9ea0abf7772',
      });

      AWS.config.credentials.get(() => {
        const s3 = new AWS.S3();

        const params = {
          Bucket: 'cloudmailing-files',
          Prefix: 'customers/'
        };

        s3.listObjectsV2(params, (err, data) => {
          if (err) {
            console.error(err);
          } else {
            const files_arr = data['Contents'].sort((a, b) => (a.LastModified < b.LastModified) ? 1 : ((b.LastModified < a.LastModified) ? -1 : 0));
            const fileKey = files_arr[0].Key;
            const s3Params = { Bucket: 'cloudmailing-files', Key: fileKey };

            var most_recent_file_date = Moment(new Date(files_arr[0].LastModified));
            var today_date = Moment(new Date());
            var is_same_day = most_recent_file_date.isSame(today_date, 'day');
            var shouldProceed = is_same_day;
            var file_date = Moment(files_arr[0].LastModified, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').valueOf();

            if (!is_same_day) {
                var msg = 'This file is from ' + most_recent_file_date.format('MM-DD-YYYY') + '. Today\'s file has not loaded to the cloud yet. Do you want to proceed with loading the older file?'
                shouldProceed = confirm(msg);
            }

            if (shouldProceed) {
                s3.getObject(s3Params, (err, data) => {
                    if (err) {
                        console.error(err);
                    } else {
                        const file = new File([data.Body], fileKey);
                        this.writeInitialData(file,file_date);
                    }
                });
            }

          }
        });
      });
    },
    searchFiles(){
      document.getElementById("assetsFieldHandle_c").click()
    },
    onChange() {
      var files_arr = [...this.$refs.file.files];
      files_arr = _.sortBy(files_arr, (obj) => parseInt(obj.name, 10));
      this.filelist = files_arr;
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-orange')) {
        event.currentTarget.classList.remove('bg-white');
        event.currentTarget.classList.add('bg-orange');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
      this.onChange();
    },
    readFiles(){
      for(var i = 0; i < this.filelist.length; i++){
        var file_name = this.filelist[i].name.toLowerCase()
        if(this.filelist[i].type.indexOf('text/csv') !== -1 && file_name.indexOf('customer') !== -1){
          this.writeInitialData(this.filelist[i], Date.now())
        }else{
          this.$store.state.isLoading = false
          this.remove(this.filelist[i])
          alert('Invalid File(s)')
        }
      }
    },
    writeInitialData(file,file_date){
      var reader = new FileReader();
      var self = this
      self.$store.state.isLoading = true
      reader.readAsBinaryString(file);

      reader.onload = function(e) {
        var data = e.target.result;
        var parsedData = Papa.parse(data, {
          header: true, // Set header to true to parse the first row as headers
          dynamicTyping: false, // Disable automatic type casting
          skipEmptyLines: true, // Skip empty lines
        });

        var json = parsedData.data;

        if (json.length === 0) {
          self.$store.state.isLoading = false;
          alert('Invalid File(s)');
          return;
        }

        setTimeout(function(){
          self.updateDatabase(json,file_date)
        },1000)
      }
    },
    updateDatabase(json,file_date){

      var self = this
      var db_object = {}
      var master_ar_account_mapping = {}
      var current_db_customers_clone = self.db.customers ? JSON.parse(JSON.stringify(self.db.customers)) : {}

      var updates = {};

      for(var i = 0; i < json.length; i++){
        var row_data = json[i]
        var customer_info = {
            'customer_number': row_data['Customer Number'] ? String(parseFloat(row_data['Customer Number'])) : '',
            'customer_name': row_data['Customer Name'] ? row_data['Customer Name'] : 'no customer name in uploaded file',
            'master_ar_account': row_data['Master AR Account'] ? String(parseFloat(row_data['Master AR Account'])) : '',
            'salesperson_code': row_data['Salesperson Code'] ? row_data['Salesperson Code'] : '',
            'credit_status': row_data['Credit Status'] ? row_data['Credit Status'] : '',

            'account_manager': row_data['Account Manager'] ? row_data['Account Manager'] : '',
            'account_specialist': row_data['Account Specialist'] ? row_data['Account Specialist'] : '',
            'has_dedicated_eta_manager': row_data['Dedicated ETA Manager?'] ? row_data['Dedicated ETA Manager?'] : '',
            'eta_contact_emails': row_data['ETA Contact Emails'] ? row_data['ETA Contact Emails'] : '',
            'send_eta_update_every_x_days': row_data['Send ETA Update Every _____ Days'] ? row_data['Send ETA Update Every _____ Days'] : '',
            'twelve_month_sales': row_data['12M Sales'] ? row_data['12M Sales'] : '',
            'twelve_month_order_count': row_data['12M Order Count'] ? row_data['12M Order Count'] : '',
            'previous_month_sales': row_data['Previous Month Sales'] ? row_data['Previous Month Sales'] : '',
            'customer_type': row_data['Customer Type'] ? row_data['Customer Type'] : '',
            'customer_category': row_data['Customer Category'] ? row_data['Customer Category'] : '',
            'collections_inquiry_emails': row_data['Collections Inquiry Emails']  ? row_data['Collections Inquiry Emails'] : '',
            'page': parseFloat(row_data['Customer Number']) + '937' + String(Math.floor(100000 + Math.random() * 900000)) + String(Math.floor(100000 + Math.random() * 900000)),
            'upload_date': Date.now(),
            'upload_by': self.user,
            'child_ar_accounts': []
        }
        console.log(row_data['Collections Inquiry Emails'])
        db_object['customer_' + customer_info['customer_number']] = customer_info

        master_ar_account_mapping[customer_info.master_ar_account] = []

      }

      for(var c in db_object){
        if(db_object[c].master_ar_account !== db_object[c].customer_number){
          master_ar_account_mapping[db_object[c].master_ar_account].push(db_object[c].customer_number)
        }
      }

      for(var ma in master_ar_account_mapping){
        if(master_ar_account_mapping[ma].length === 0){
          delete master_ar_account_mapping[ma]
        }else{
          master_ar_account_mapping[ma].sort()
        }
      }

      for(var c in db_object){
        var customer_info = db_object[c]

        if(master_ar_account_mapping[customer_info.customer_number]){
          customer_info.child_ar_accounts = master_ar_account_mapping[customer_info.master_ar_account]
        }

        if(!current_db_customers_clone['customer_' + customer_info['customer_number']]){ // new customer
          firebase.database().ref('customers').child('customer_' + customer_info['customer_number']).set(db_object['customer_' + customer_info.customer_number])
          self.setCustomerPageMapping(customer_info['customer_number'],customer_info['page'])
        }else{ // existing customer

          var customer_clone = JSON.parse(JSON.stringify(current_db_customers_clone['customer_' + customer_info['customer_number']]))

          customer_clone.customer_name = customer_info.customer_name
          customer_clone.master_ar_account = customer_info.master_ar_account
          customer_clone.salesperson_code = customer_info.salesperson_code
          customer_clone.credit_status = customer_info.credit_status
          customer_clone.child_ar_accounts = customer_info.child_ar_accounts
          customer_clone.account_manager = customer_info.account_manager
          customer_clone.account_specialist = customer_info.account_specialist
          customer_clone.has_dedicated_eta_manager = customer_info.has_dedicated_eta_manager
          customer_clone.eta_contact_emails = customer_info.eta_contact_emails
          customer_clone.send_eta_update_every_x_days = customer_info.send_eta_update_every_x_days
          customer_clone.twelve_month_sales = customer_info.twelve_month_sales
          customer_clone.twelve_month_order_count = customer_info.twelve_month_order_count
          customer_clone.previous_month_sales = customer_info.previous_month_sales
          customer_clone.customer_type = customer_info.customer_type
          customer_clone.customer_category = customer_info.customer_category
          customer_clone.collections_inquiry_emails = customer_info.collections_inquiry_emails
          updates[`/customers/customer_${customer_info['customer_number']}`] = customer_clone;

        }
      }

      firebase.database().ref().update(updates).then(function() {
        firebase.database().ref('last_customer_file_upload').set(file_date)
        firebase.database().ref('last_customer_file_pull').set(Date.now())
        self.filelist = []
        self.$store.state.isLoading = false
      }).catch(function(error) {
        alert(error);
        self.filelist = []
        self.$store.state.isLoading = false
      });


    },
    setCustomerPageMapping(customer_number,random_page_key){
      firebase.database().ref('customers_page_mapping').child(random_page_key).set('customer_'+customer_number)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .dropzone{
    width: 100%;
    height: 50px;
    border: 2px dashed orange;
    border-radius: 4px;
  }
  .bg-white{
    background: white;
  }
  .bg-orange{
    background: orange;
  }
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
    padding-right: 10px;
  }

  .table-container{
    position: relative;
    top: 5px;
    margin:0px 20px;
  }
</style>
