<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">

      <v-card-text>

        <v-card-title style="margin-left:-15px">Dispute Purchase Order #{{po}}</v-card-title>

        <v-table :height="$vuetify.display.height - 575 + 'px'" fixed-header style="border:1px solid #999999;border-radius:5px;margin-bottom:20px">
          <thead>
            <tr>
              <th>Manufacturer Product Number</th>
              <th>Vendor Product Number</th>
              <th>Product Description</th>
              <th>Quantity Received</th>
              <th>Quantity Open</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in po_items">
              <td>{{item.manufacturer_part_number}}</td>
              <td>{{item.vendor_part_number}}</td>
              <td>{{item.product_description}}</td>
              <td>{{item.qty_rcv}}</td>
              <td>{{item.qty_open}}</td>
            </tr>
          </tbody>
        </v-table>

        <v-text-field variant="outlined" placeholder="*Your Name" v-model="submitted_by"></v-text-field>

        <v-text-field variant="outlined" placeholder="*Your Email" v-model="submitted_by_email"></v-text-field>

        <v-textarea variant="outlined" height="20" placeholder="*Please describe the issue" v-model="note"></v-textarea>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveDispute()" :disabled="submitted_by.trim() === '' || submitted_by_email.trim() === '' || note.trim() === ''">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import { emailMixin } from '../mixins/emailMixin.js'

export default {
  name: 'DisputePurchaseOrderPopUp',
  components: {},
  mixins: [emailMixin],
  props:{
    po: String
  },
  data(){
    return{
      dialog: true,
      po_items: [],
      note: '',
      submitted_by: '',
      submitted_by_email: ''
    }
  },
  beforeMount(){
    var po_items = []
    var po_date = this.db.purchase_orders[this.po].po_date
    for(var item_key in this.db.purchase_orders[this.po].items){
      var item = JSON.parse(JSON.stringify(this.db.purchase_orders[this.po].items[item_key]))
      item.item_key = item_key
      po_items.push(item)
    }
    this.po_items = po_items
  },
  computed: {
    ...mapGetters(['user','db']),
  },
  methods:{
    saveDispute(){
      var self = this

      var email = {
        po: self.po,
        vendor: self.db.purchase_orders[self.po].vendor_name,
        submitted_by: self.submitted_by,
        submitted_by_email: self.submitted_by_email,
        note: JSON.stringify(self.note).replace(/\\n/g, '<br>')
      }

      firebase.database().ref('purchase_orders').child(self.po).child('action_vendor_po_conf_response').set('Disputed')
      firebase.database().ref('purchase_orders').child(self.po).child('shared_notes').push({note: self.note, submitted_by: self.submitted_by, submitted_date: Date.now()}).then(function(){
        self.poDisputeEmail(email)
        self.$emit('close')
      })
    }
  }
}
</script>

<style>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
</style>


