<template>
  <div class="home">
    <v-container fluid style="padding:10px">
      <v-row dense>
        <v-col v-for="item in menu" :key="item" cols="3">
          <v-sheet @click="item.enabled ? $router.push(item.link) : false" color="orange" height="96" elevation="5" rounded :class="{'enabled': item.enabled, 'disabled': !item.enabled}">
            <div class="menu-item-content">
              <div class="menu-item-title">{{item.title}}</div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'

export default {
  name: 'HomeView',
  components: {},
  data(){
    return{
      menu: [
        {'link':'/admin','title': 'Administration', enabled: true},
        {'link':'/purchasing','title': 'Purchasing', enabled: true},
        {'link':'/outside_sales','title': 'Outside Sales', enabled: false},
        {'link':'/inside_sales','title': 'Inside Sales', enabled: false},
        {'link':'/goals_tracking','title': 'Goals and Objective Tracking', enabled: false},
        {'link':'/accounts_payable','title': 'Accounts Payable', enabled: false},
        {'link':'/receiving','title': 'Receiving', enabled: false},
        {'link':'/delivery','title': 'Delivery', enabled: false},
        {'link':'/roles_accountability','title': 'Roles & Accountability', enabled: false},
        {'link':'/repair_shop','title': 'Repair Shop', enabled: false},
        {'link':'/accounts_receivable','title': 'Accounts Receivable', enabled: false},
        {'link':'/hr','title': 'HR', enabled: false},
        {'link':'/insentive_tracking','title': 'Incentive Tracking', enabled: false},
        // {'link':'/automation','title': 'Automation Tests', enabled: true},
      ]
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      user: 'user',
      db: 'db'
    }),
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }
  },
  methods:{

  }
}
</script>

<style scoped>

  .v-sheet{
    cursor: pointer;
    text-align: center;
    padding: 2px;
  }

  .disabled{
    opacity:0.2;
    cursor: not-allowed;
  }

  .menu-item-content{
    display: table;
    width: 100%;
    height: 100%;
  }

  .menu-item-title{
    display: table-cell;
    vertical-align: middle;
    line-height: 100%;
    font-weight: 500;
  }

</style>
