<template>
  <div class="eta_master">
    <div style="font-size: 22px; color: gray;padding:10px">ETA Master (Unreceived Products Only)</div>

    <div v-if="items_table_data.length">
      <v-row style="width:750px;margin:0px 0px">
        <v-col cols="6">
          <div style="width:350px;">
            <v-text-field label="Search Items..." variant="solo" density="comfortable" hide-details="true" @input="debouncedSearch($event)"></v-text-field>
          </div>
        </v-col>
      </v-row>
      <div class="table-container">
          <Vue3EasyDataTable
            :headers="eta_headers"
            :items="items_table_data"
            :search-value="searchValueItems"
            :table-height="table_height"
            :rows-per-page="250"
            :rows-items="[250, 500, 1000, 5000]"
            :hide-rows-per-page="false"
            :hide-footer="false"
          >

          <template #item-po_number="item">
            <span style='color:#ff7214;cursor:pointer;' @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
          </template>

          <template #item-vendor_name="item">
            <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" style="color:#ff7214">{{item.vendor_name}}</router-link>
          </template>

          <template #item-po_date_sortable="item">
            {{item.po_date_formatted}}
          </template>

          <template #item-product="item">
            <span style='color:#ff7214;cursor:pointer' @click="openItemDetailPopup(item.po_number,item.item_key)">{{item.product}}</span>
          </template>

          <template #item-shared_notes="item">
            <div v-for="n in item.shared_notes" style="border-bottom:1px dashed;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:300px">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
            </div>
          </template>

          <template #item-internal_notes="item">
            <div v-for="n in item.internal_notes" style="border-bottom:1px dashed;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:300px;">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
            </div>
          </template>

          <template #item-full_lead_time_days="item">
            <span v-if="item.full_lead_time_days === 9999"></span>
            <span v-if="item.full_lead_time_days !== 9999 && item.full_lead_time_days === 1">{{item.full_lead_time_days + ' Day'}}</span>
            <span v-if="item.full_lead_time_days !== 9999 && item.full_lead_time_days > 1">{{item.full_lead_time_days + ' Days'}}</span>
            <span v-if="item.full_lead_time_days !== 9999 && item.full_lead_time_days === 0">{{item.full_lead_time_days + ' Days'}}</span>
          </template>

          <template #item-remaining_lead_time_days="item">
            <span v-if="item.remaining_lead_time_days === 9999"></span>
            <span v-if="item.remaining_lead_time_days !== 9999 && item.remaining_lead_time_days === 1">{{item.remaining_lead_time_days + ' Day'}}</span>
            <span v-if="item.remaining_lead_time_days !== 9999 && item.remaining_lead_time_days > 1">{{item.remaining_lead_time_days + ' Days'}}</span>
            <span v-if="item.remaining_lead_time_days !== 9999 && item.remaining_lead_time_days === 0">Due Today</span>
            <span v-if="item.remaining_lead_time_days !== 9999 && item.remaining_lead_time_days === -1" style="color:red">{{ Math.abs(item.remaining_lead_time_days) + ' Day Past Due'}}</span>
            <span v-if="item.remaining_lead_time_days !== 9999 && item.remaining_lead_time_days < -1" style="color:red">{{ Math.abs(item.remaining_lead_time_days) + ' Days Past Due'}}</span>
          </template>

          <template #item-eta_last_requested_sortable="item">
            {{item.eta_last_requested_formatted}}
          </template>

          <template #item-eta_last_provided_sortable="item">
            {{item.eta_last_provided_formatted}}
          </template>

          <template #item-flag_critical="item">
            <div class="circle" v-if="item.flag_critical === 'Y'">
              <span>!</span>
            </div>
          </template>

        </Vue3EasyDataTable>
      </div>
    </div>
    <PurchaseOrderDetailPopUp v-if="po_detail" :po='po_for_component' @close="closePurchaseOrderDetailPopup()" />
    <ItemDetailPopUp v-if="item_detail" :po='po_for_component' :itemkey='item_for_component' @close="closeItemDetailPopup()" />
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment-business-days'
import ItemDetailPopUp from '@/components/ItemDetailPopUp.vue'
import PurchaseOrderDetailPopUp from '@/components/PurchaseOrderDetailPopUp.vue'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { getDatabase, ref, query, orderByChild, equalTo, onChildAdded, onChildChanged, onChildRemoved } from 'firebase/database';
import { debounce } from 'lodash';

export default {
  name: 'ETAMaster',
  components: {PurchaseOrderDetailPopUp,ItemDetailPopUp,Vue3EasyDataTable},
  data(){
    return {
      po_detail: false,
      item_detail: false,
      po_for_component: null,
      item_for_component: null,
      table_height: 400,
      searchValueItems: '',
      include_completed: false,
      eta_headers: [
        // { text: "UNIQUE KEY", value: "unique_key", sortable: true,fixed: true, width: 150 },
        { text: "Flagged Critical", value: "flag_critical", sortable: true,fixed: true, width: 75 },
        { text: "RH Product #", value: "product", sortable: true,fixed: true },
        { text: "PO Number", value: "po_number", sortable: true,fixed: true, width: 100 },
        { text: "Item ETA Status", value: "eta_status", sortable: true,fixed: true, width: 65 },
        { text: "Item ETA", value: "eta", sortable: true,fixed: true, width: 55 },
        { text: "ETA Last Provided", value: "eta_last_provided_sortable", sortable: true },
        { text: "ETA Last Requested", value: "eta_last_requested_sortable", sortable: true },
        { text: "ETA Requests Count", value: "eta_escalation_requests_cnt", sortable: true },
        { text: "Pending ETA Request", value: "pending_eta_request", sortable: true },
        { text: "Remaining Lead Time", value: "remaining_lead_time_days", sortable: true },
        { text: "Provided Lead Time", value: "full_lead_time_days", sortable: true },
        { text: "Mfg Product #", value: "manufacturer_part_number", sortable: true },
        { text: "Vendor Part #", value: "vendor_part_number", sortable: true },
        { text: "Qty Not Yet Received", value: "quantity_backordered", sortable: false },
        { text: "Description", value: "product_description", sortable: true },
        { text: "PO Date", value: "po_date_sortable", sortable: true },
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "Shipping Company", value: "shipping_company", sortable: true },
        { text: "Tracking #", value: "tracking_number", sortable: true },
        { text: "Drop Ship", value: "is_drop_ship", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: true },
        { text: "Shared Notes", value: "shared_notes", sortable: true }
      ],
      items_table_data: []
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){this.$router.push('/');return}
    this.$store.state.isLoading = true
  },
  mounted() {

    setTimeout(() => {
      const db = getDatabase();
      const purchaseOrdersRef = ref(db, 'purchase_orders');
      const purchaseOrders = query(purchaseOrdersRef, orderByChild('action_po_complete'), equalTo('N'));

      this.onChildAddedListener = onChildAdded(purchaseOrders, (snapshot) => {
        const po = snapshot.val();
        po.id = snapshot.key;
        po.po_confirmation_status = this.po_confirmation_status(po)
        po.po_date_sortable = Moment(po.po_date, 'M/D/YY').format('YYYY-MM-DD');
        po.po_date_formatted = Moment(po.po_date, 'M/D/YY').format('MM/DD/YY');
        po.vendor_page = this.db.vendors['vendor_'+po.vendor_number] ? this.db.vendors['vendor_'+po.vendor_number].page : ''
        po.vendor_po_confirmation_email = this.db.vendors['vendor_'+po.vendor_number].po_confirmation_email
        po.vendor_auto_confirms = this.db.vendors['vendor_'+po.vendor_number].self_confirm_po
        for(var i in po.items){
          var item = po.items[i]

          if(item.line_complete === 'N'){
            item.unique_key = po.id + ':' + i
            item.eta_status = this.item_eta_status(po,item.item_key)
            item.po_number = po.po_number
            item.vendor_name = po.vendor_name
            item.vendor_number = po.vendor_number
            item.vendor_page = po.vendor_page
            item.po_date = po.po_date
            item.po_date_sortable = po.po_date_sortable
            item.po_date_formatted = po.po_date_formatted
            item.eta_status = this.item_eta_status(po,item.item_key)
            item.eta_last_requested_sortable = item.eta_last_requested ? Moment(item.eta_last_requested).startOf('day') : ''
            item.eta_last_requested_formatted = item.eta_last_requested ? this.formatDate(item.eta_last_requested_sortable) : ''
            item.eta_last_provided_sortable = item.eta_last_provided ? Moment(item.eta_last_provided).startOf('day') : ''
            item.eta_last_provided_formatted = item.eta_last_provided ? this.formatDate(item.eta_last_provided_sortable) : ''
            item.pending_eta_request = item.pending_eta_request ? 'Y' : ''
            item.quantity_backordered = item.qty_open + ' of ' + item.po_quantity
            item.po_confirmation_status = po.po_confirmation_status
            item.flag_critical = item.flag_critical ? 'Y' : ''
            item.is_drop_ship = item.drop_ship ? 'YES' : 'NO'
            var date_a = Moment(item.po_date, 'M/D/YY').format('YYYY-MM-DD')
            var date_b = Moment(item.eta,'M/D/YY')
            var date_c = Moment().startOf('day')
            item.full_lead_time_days = item.eta !== '' && item.eta !== 'UTP' && item.eta !== 'TBD' ? date_b.diff(date_a, 'days') : 9999
            item.remaining_lead_time_days = item.eta !== '' && item.eta !== 'UTP' && item.eta !== 'TBD' ? date_b.diff(date_c, 'days') : 9999

            this.items_table_data.push(item)
          }
        }
        this.$store.state.isLoading = false;
      });

      this.onChildChangedListener = onChildChanged(purchaseOrders, (snapshot) => {
        const po = snapshot.val();
        po.id = snapshot.key;
        po.po_confirmation_status = this.po_confirmation_status(po);
        po.po_date_sortable = Moment(po.po_date, 'M/D/YY').format('YYYY-MM-DD');
        po.po_date_formatted = Moment(po.po_date, 'M/D/YY').format('MM/DD/YY');
        po.vendor_page = this.db.vendors['vendor_' + po.vendor_number] ? this.db.vendors['vendor_' + po.vendor_number].page : '';
        po.vendor_po_confirmation_email = this.db.vendors['vendor_' + po.vendor_number].po_confirmation_email;
        po.vendor_auto_confirms = this.db.vendors['vendor_' + po.vendor_number].self_confirm_po;

        for (var i in po.items) {
          var item = po.items[i];
          item.unique_key = po.id + ':' + i;
          item.eta_status = this.item_eta_status(po, item.item_key);
          item.po_number = po.po_number;
          item.vendor_name = po.vendor_name;
          item.vendor_number = po.vendor_number;
          item.vendor_page = po.vendor_page;
          item.po_date = po.po_date;
          item.po_date_sortable = po.po_date_sortable;
          item.po_date_formatted = po.po_date_formatted;
          item.eta_status = this.item_eta_status(po, item.item_key);
          item.eta_last_requested_sortable = item.eta_last_requested ? Moment(item.eta_last_requested).startOf('day') : '';
          item.eta_last_requested_formatted = item.eta_last_requested ? this.formatDate(item.eta_last_requested_sortable) : '';
          item.eta_last_provided_sortable = item.eta_last_provided ? Moment(item.eta_last_provided).startOf('day') : '';
          item.eta_last_provided_formatted = item.eta_last_provided ? this.formatDate(item.eta_last_provided_sortable) : '';
          item.pending_eta_request = item.pending_eta_request ? 'Y' : '';
          item.quantity_backordered = item.qty_open + ' of ' + item.po_quantity;
          item.po_confirmation_status = po.po_confirmation_status;
          item.flag_critical = item.flag_critical ? 'Y' : ''
          var date_a = Moment(item.po_date, 'M/D/YY').format('YYYY-MM-DD');
          var date_b = Moment(item.eta, 'M/D/YY');
          var date_c = Moment().startOf('day');
          item.full_lead_time_days = item.eta !== '' && item.eta !== 'UTP' && item.eta !== 'TBD' ? date_b.diff(date_a, 'days') : 9999;
          item.remaining_lead_time_days = item.eta !== '' && item.eta !== 'UTP' && item.eta !== 'TBD' ? date_b.diff(date_c, 'days') : 9999;

          if (item.line_complete === 'N') {
            const index = this.items_table_data.findIndex((li) => li.unique_key === item.unique_key);
            if (index !== -1) {
              this.items_table_data.splice(index, 1, item);
            }
          } else {
            const index = this.items_table_data.findIndex((li) => li.unique_key === item.unique_key);
            if (index !== -1) {
              this.items_table_data.splice(index, 1); // REMOVE ITEM
            }
          }
        }
      });
      // IGNORE ONCHILDREMOVED BECAUSE THIS SITUATION DOES NOT CURRENTLY HAPPEN
      // this.onChildRemovedListener = onChildRemoved(purchaseOrders, (snapshot) => {
      //   const id = snapshot.key;
      //   const index = this.purchase_orders_table_data.findIndex((po) => po.id === id);
      //   if (index !== -1) {
      //     this.items_table_data.splice(index, 1);
      //   }
      // });
    }, 1000);
  },
  beforeUnmount() {
    if (this.onChildAddedListener) {
      this.onChildAddedListener();
    }
    if (this.onChildChangedListener) {
      this.onChildChangedListener();
    }
    if (this.onChildRemovedListener) {
      this.onChildRemovedListener();
    }
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status','item_eta_status']),
  },
  methods: {
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 260
    },
    debouncedSearch: debounce(function(event) {
      this.searchValueItems = event.target._value.replace(/\*/g, "");
    }, 500),
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    openPurchaseOrderDetailPopup(po){
      this.po_for_component = po
      this.po_detail = true
    },
    closePurchaseOrderDetailPopup(){
      this.po_for_component = null
      this.po_detail = false
    },
    openItemDetailPopup(po,item){
      this.po_for_component = po
      this.item_for_component = item
      this.item_detail = true
    },
    closeItemDetailPopup(){
      this.po_for_component = null
      this.item_for_component = null
      this.item_detail = false
    }
  }
}
</script>

<style scoped>
  .table-container{
    position: relative;
    top: 5px;
    margin:0px 10px;
  }
.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
}
</style>

