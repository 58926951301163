<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="100vw" min-height="95vh" style="background:lightgray">
      <v-card-text>

        <v-card class="pa-2 ma-2" outlined tile>
          <v-row>
            <v-col cols="12" sm="6">
              <div><span class="line-header">PO:</span> {{po}}</div>
              <div><span class="line-header">PO Date:</span> {{db.purchase_orders[po].po_date}}</div>
              <div><span class="line-header">Vendor:</span> {{db.purchase_orders[po].vendor_name}}</div>
            </v-col>
            <v-col cols="12" sm="6">
              <div><span class="line-header">Buyer:</span> {{db.purchase_orders[po].buyer_name}}</div>
              <div><span class="line-header">Upload By:</span> {{db.users[db.purchase_orders[po].upload_by].email}}</div>
              <div><span class="line-header">Upload Date:</span> {{formatDate(db.purchase_orders[po].upload_date)}}</div>
            </v-col>
          </v-row>
        </v-card>


        <v-card class="pa-2 ma-2" outlined tile>
          <span class="line-header">Confirmation Status</span>
          <div>{{po_confirmation_status(db.purchase_orders[po])}} <span v-if="po_confirmation_status(db.purchase_orders[po]) === 'Confirmed'">on {{formatDate(db.purchase_orders[po].vendor_po_conf_date)}} by {{confirmedBy(db.purchase_orders[po].vendor_po_conf_user)}} </span></div>

          <v-btn size="x-small" style="background:#ff7214;color:white;margin-right:15px" @click="openPurchaseOrderConfirmationPopUp()" v-if="['Not Requested', 'Pending', 'Disputed'].indexOf(po_confirmation_status(db.purchase_orders[po])) !== -1">Confirm PO</v-btn>

          <v-btn size="x-small" style="background:#ff7214;color:white" @click="openDisputePurchaseOrderPopUp()" v-if="['Not Requested', 'Pending', 'Confirmed'].indexOf(po_confirmation_status(db.purchase_orders[po])) !== -1">Dispute PO</v-btn>

          <div style="font-size:10px;font-style:italic;margin-top:20px">Confirmation Last Requested: {{db.purchase_orders[po].po_confirmation_last_requested ? formatDateTime(db.purchase_orders[po].po_confirmation_last_requested)  : 'none'}}</div>
          
          <div v-if="db.purchase_orders[po].po_confirmation_last_requested" style="font-size:10px;font-style:italic;margin-top:5px">
            Confirmation Request Log:
            <div v-for="attempt in db.purchase_orders[po].po_confirmation_request_history">
              {{formatDateTime(attempt.requested_date)}}
            </div>
          </div>

        </v-card>

        <v-card class="pa-2 ma-2" outlined tile>

          <v-row>
            <v-col cols="12" sm="6">
              <div class="line-header">Shared Notes</div>
              <v-btn size="x-small" style="background:#ff7214;color:white" @click="addNote('shared')">Add Note</v-btn>
              <div v-for="n in db.purchase_orders[po].shared_notes" class="note-container">
                <div class="note">{{n.note}}</div>
                <div class="note-detail">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="line-header">Internal Notes</div>
              <v-btn size="x-small" style="background:#ff7214;color:white" @click="addNote('internal')">Add Note</v-btn>
              <div v-for="n in db.purchase_orders[po].internal_notes" class="note-container">
                <div class="note">{{n.note}}</div>
                <div class="note-detail">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
              </div>
            </v-col>
          </v-row>

        </v-card>

        <v-card class="pa-2 ma-2" outlined tile>
<!--           <v-table fixed-header>
            <thead>
              <tr>
                <th>Product</th>
                <th>Manufacturer Product Number</th>
                <th>Vendor Product Number</th>
                <th>Product Description</th>
                <th>Qty Not Yet Received</th>
                <th>Drop Ship</th>
                <th>Tracking Number<div><v-btn size="x-small" style="background:#ff7214;color:white;margin-bottom:10px" @click="openBulkTrackingPopUp()">BULK UPDATE</v-btn></div></th>
                <th>Internal Notes</th>
                <th>Shared Notes</th>
                <th>ETA</th>
                <th>ETA Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in po_items">
                <td><span style='color:#ff7214;cursor:pointer;font-weight:bold' @click="openItemDetailPopup(item.item_key)">{{item.product}}</span></td>
                <td>{{item.manufacturer_part_number}}</td>
                <td>{{item.vendor_part_number}}</td>
                <td>{{item.product_description}}</td>
                <td>{{item.qty_open + ' of ' + item.po_quantity}}</td>
                <td>{{item.drop_ship ? 'YES' : 'NO'}}</td>
                <td>{{item.tracking_number}} {{item.shipping_company ? '(' + item.shipping_company + ')' : '' }}</td>
                <td>
                  <div v-for="n in item.internal_notes" class="note-container" style="min-width:300px">
                    <div class="note">{{n.note}}</div>
                    <div class="note-detail">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                </td>
                <td>
                  <div v-for="n in item.shared_notes" class="note-container" style="min-width:300px">
                    <div class="note">{{n.note}}</div>
                    <div class="note-detail">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                </td>
                <td>
                  {{item.eta}}
                  <br>
                  <v-btn size="x-small" style="background:#ff7214;color:white" @click="updateEta(item.item_key)">Update ETA</v-btn>
                </td>
                <td>{{item_eta_status(db.purchase_orders[po], item.item_key)}}</td>
              </tr>
            </tbody>
          </v-table>
 -->
          <div style="margin:10px 10px 0px 0px">
            <!-- <div style="float:right;"><v-btn size="x-small" style="background:#ff7214;color:white;margin-bottom:10px" @click="openBulkTrackingPopUp()">BULK TRACKING UPDATE</v-btn></div> -->
            <v-text-field style="width: 400px;margin-bottom: 10px;" label="Search Items..." variant="solo" density="comfortable" hide-details="true" @input="debouncedSearch($event)"></v-text-field>
          </div>

          <Vue3EasyDataTable
            :headers="items_headers"
            :items="po_items"
            :search-value="searchValueItems"
            :table-height="table_height"
            :rows-per-page="50000"
            :hide-rows-per-page="true"
            :hide-footer="false"
          >

          <template #header-tracking_info="header">
            <div style="color:#ff7214;" @click="openBulkTrackingPopUp()">Tracking Info</div>
          </template>

          <template #item-product="item">
            <span style='color:#ff7214;cursor:pointer;font-weight:bold' @click="openItemDetailPopup(item.item_key)">{{item.product}}</span>
          </template>

          <template #item-internal_notes="item">
            <div v-for="n in item.internal_notes" style="border-bottom:1px dashed;margin-top:5px;margin-bottom:5px;line-height:100%">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
            </div>
          </template>

          <template #item-shared_notes="item">
            <div v-for="n in item.shared_notes" style="border-bottom:1px dashed;margin-top:5px;margin-bottom:5px;line-height:100%">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
            </div>
          </template>

          <template #item-eta="item">
            {{item.eta}}
            <br>
            <v-btn size="x-small" style="background:#ff7214;color:white;margin-bottom:5px" @click="updateEta(item.item_key)">Update ETA</v-btn>
          </template>

          </Vue3EasyDataTable>
        </v-card>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:#ff7214;color:white" @click="$emit('close')">CLOSE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
  <NotePurchaseOrderPopUp v-if="note_popup" @close="note_popup = false" :po="po" :type="note_type"/>
  <ItemDetailPopUp v-if="item_detail" :po='po' :itemkey='item_for_component' @close="closeItemDetailPopup()" />
  <PurchaseOrderConfirmationPopUp v-if="show_po_confirmation_popup" :po='po' :vendor='vendor_info' @close="closePurchaseOrderConfirmationPopUp()" />
  <DisputePurchaseOrderPopUp v-if="show_dispute_po_popup" :po='po' @close="closeDisputePurchaseOrderPopUp()" />
  <UpdateEtaPopUp v-if="item_eta_popup" @close="closeUpdateEtaPopUp()" :po="po" :itemkey='item_for_component'/>
  <BulkTrackingPopUp v-if="bulk_tracking_popup" @close="closeBulkTrackingPopUp()" :po="po"/>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import NotePurchaseOrderPopUp from '@/components/NotePurchaseOrderPopUp.vue'
import ItemDetailPopUp from '@/components/ItemDetailPopUp.vue'
import DisputePurchaseOrderPopUp from '@/components/DisputePurchaseOrderPopUp.vue'
import PurchaseOrderConfirmationPopUp from '@/components/PurchaseOrderConfirmationPopUp.vue'
import UpdateEtaPopUp from '@/components/UpdateEtaPopUp.vue'
import BulkTrackingPopUp from '@/components/BulkTrackingPopUp.vue'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { debounce } from 'lodash';

export default {
  name: 'PurchaseOrderDetailPopUp',
  components: {Vue3EasyDataTable,NotePurchaseOrderPopUp,ItemDetailPopUp,PurchaseOrderConfirmationPopUp,DisputePurchaseOrderPopUp,UpdateEtaPopUp,BulkTrackingPopUp},
  mixins: [],
  emits: ["close"],
  props:{
    po: String
  },
  data(){
    return{
      dialog: true,
      late_arrivals: false,
      note_popup: false,
      item_detail: false,
      po_date: '',
      item_for_component: null,
      note_type: null,
      show_po_confirmation_popup: false,
      show_dispute_po_popup: false,
      vendor_info: null,
      item_eta_popup: false,
      bulk_tracking_popup: false,

      table_height: 400,
      searchValueItems: '',
      items_headers: [
        { text: "Product", value: "product", sortable: true },
        { text: "Manufacturer Product Number", value: "manufacturer_part_number", sortable: true },
        { text: "Vendor Product Number", value: "vendor_part_number", sortable: true },
        { text: "Product Description", value: "product_description", sortable: true },
        { text: "Qty Not Yet Received", value: "qty_not_yet_received", sortable: true },
        { text: "Drop Ship", value: "drop_ship_y_n", sortable: true },
        { text: "Tracking Number", value: "tracking_info", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: false },
        { text: "Shared Notes", value: "shared_notes", sortable: false },
        { text: "ETA", value: "eta", sortable: false },
        { text: "ETA Status", value: "item_eta_status", sortable: true },
      ],    
    }
  },
  watch: {

  },
  beforeMount(){
    var self = this
    var purchase_order_clone = JSON.parse(JSON.stringify( self.db.purchase_orders[self.po] ))
    self.vendor_info = self.db.vendors['vendor_'+purchase_order_clone.vendor_number]
    self.po_date = purchase_order_clone.po_date
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status','item_eta_status']),
    po_items(){
      var purchase_order_clone = JSON.parse(JSON.stringify( this.db.purchase_orders[this.po] ))
      var po_items_arr = []
      for(var item_key in purchase_order_clone.items){
        purchase_order_clone.items[item_key].qty_not_yet_received = purchase_order_clone.items[item_key].qty_open + ' of ' + purchase_order_clone.items[item_key].po_quantity
        purchase_order_clone.items[item_key].item_eta_status = this.item_eta_status(this.db.purchase_orders[this.po], purchase_order_clone.items[item_key].item_key)

        purchase_order_clone.items[item_key].tracking_info = purchase_order_clone.items[item_key].tracking_number + ' ' + (purchase_order_clone.items[item_key].shipping_company ? '(' + purchase_order_clone.items[item_key].shipping_company + ')' : '')

        purchase_order_clone.items[item_key].drop_ship_y_n = purchase_order_clone.items[item_key].drop_ship ? 'YES' : 'NO'

        po_items_arr.push(purchase_order_clone.items[item_key])
      }
      return po_items_arr
    }
  },
  methods:{
    confirmedBy(user){
      if(user === 'vendor'){
        return 'vendor'
      }

      if(user){
        return this.db.users[user].first_name + ' ' + this.db.users[user].last_name
      }

      return 'unknown'
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    formatDateTime(date){
      return Moment(date).format('MM/DD/YY h:mma')
    }, 
    debouncedSearch: debounce(function(event) {
      this.searchValueItems = event.target._value
    }, 500),   
    addNote(type){
      this.note_type = type
      this.note_popup = true
    },
    openBulkTrackingPopUp(){
      console.log('fda.')
      this.bulk_tracking_popup = true
    },
    closeBulkTrackingPopUp(){
      this.bulk_tracking_popup = false
    },    
    openDisputePurchaseOrderPopUp(){
      this.show_dispute_po_popup = true
    },
    closeDisputePurchaseOrderPopUp(){
      this.show_dispute_po_popup = false
    },
    openPurchaseOrderConfirmationPopUp(){
      this.show_po_confirmation_popup = true
    },
    closePurchaseOrderConfirmationPopUp(){
      this.show_po_confirmation_popup = false
    },
    openItemDetailPopup(item){
      this.item_for_component = item
      this.item_detail = true
    },
    closeItemDetailPopup(){
      this.item_for_component = null
      this.item_detail = false
    },
    updateEta(itemkey){
      this.item_for_component = itemkey
      this.item_eta_popup = true
    },
    closeUpdateEtaPopUp(){
      this.item_for_component = null
      this.item_eta_popup = false
    },
  }
}
</script>

<style>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .line-header{
    font-weight: bold;
  }
  .note-container{
    border-bottom: 1px dashed lightgray;
  }
  .note{
    font-size: 12px;
  }
  .note-detail{
    font-style: italic;
    font-size: 10px;
  }
</style>


