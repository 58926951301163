// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    defaultTheme: 'riverhawkTheme',
    themes: {
      options: { customProperties: true },
      riverhawkTheme: {
        dark: false,
        colors: {
          orange: '#ff7214',
          gray: '#696969'
        }
      }
    }
  }
})
