<template>
  <div class="vendors-import">

    <div class="dropzone bg-white" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input type="file" name="fields[assetsFieldHandle_v][]" id="assetsFieldHandle_v" @change="onChange" ref="file" style="opacity: 0;display:none"/>
      <div v-if="!filelist.length" style="padding-top:10px;padding-left:25px;color:gray;display:flex;align-items:center;">
        <v-icon class="mdi mdi-file-upload-outline orange--text"></v-icon>
        <span style="padding-left:10px;">Vendors File Drop</span>
      </div>
    </div>
    <div style="color:red;font-size:10px;font-weight:bold;padding-top:5px;color:white">.</div>
    <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;position:relative;">
      <v-btn style="background:#ff7214;color:white;height:15px;width:75px;font-size:8px;margin-right:10px;" @click="pullData()">PULL DATA</v-btn>
      <div>
        <div style="font-size:12px;color:gray;font-style:italic;">Last Imported File Date: {{lastUploadDate}}</div>
        <div style="font-size:12px;color:gray;font-style:italic;">Last Pull Data Date: {{lastPullDate}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as XLSX from "xlsx"
import Papa from "papaparse"
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import AWS from 'aws-sdk';

export default {
  name: 'VendorsImport',
  components: {

  },
  data(){
    return {
      filelist: []
    }
  },
  watch: {
    filelist(val){
      if(val.length > 0){
        this.readFiles()
      }
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    vendors_table_data(){
      var vendors = JSON.parse(JSON.stringify(this.db.vendors))
      var table_arr = []
      for(var v in vendors){

        vendors[v].upload_date_sortable = Moment(vendors[v].upload_date).startOf('day')
        vendors[v].upload_date_formatted = this.formatDate(vendors[v].upload_date_sortable)
        vendors[v].upload_by = this.db.users[vendors[v].upload_by].email
        vendors[v].self_confirm_po = vendors[v].self_confirm_po ? 'Y' : ''
        vendors[v].self_track_eta = vendors[v].self_track_eta ? 'Y' : ''
        vendors[v].number = parseInt(vendors[v].number)
        vendors[v].vendor_key = v
        table_arr.push(vendors[v])
      }
      return table_arr
    },
    lastUploadDate(){
      return Moment(this.db.last_vendor_file_upload).format('MM/DD/YY hh:mma')
    },
    lastPullDate(){
      return this.db.last_vendor_file_pull ? Moment(this.db.last_vendor_file_pull).format('MM/DD/YY hh:mma') : false
    }
  },
  methods: {
    async pullData(){
      AWS.config.region = 'us-east-1';

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:44b121e3-3117-4e3a-b0b1-a9ea0abf7772',
      });

      AWS.config.credentials.get(() => {
        const s3 = new AWS.S3();

        const params = {
          Bucket: 'cloudmailing-files',
          Prefix: 'vendors/'
        };

        s3.listObjectsV2(params, (err, data) => {
          if (err) {
            console.error(err);
          } else {
            const files_arr = data['Contents'].sort((a, b) => (a.LastModified < b.LastModified) ? 1 : ((b.LastModified < a.LastModified) ? -1 : 0));
            const fileKey = files_arr[0].Key;
            const s3Params = { Bucket: 'cloudmailing-files', Key: fileKey };

            var most_recent_file_date = Moment(new Date(files_arr[0].LastModified));
            var today_date = Moment(new Date());
            var is_same_day = most_recent_file_date.isSame(today_date, 'day');
            var shouldProceed = is_same_day;
            var file_date = Moment(files_arr[0].LastModified, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').valueOf();

            if (!is_same_day) {
                var msg = 'This file is from ' + most_recent_file_date.format('MM-DD-YYYY') + '. Today\'s file has not loaded to the cloud yet. Do you want to proceed with loading the older file?'
                shouldProceed = confirm(msg);
            }

            if (shouldProceed) {
              s3.getObject(s3Params, (err, data) => {
                if (err) {
                  console.error(err);
                } else {
                  const file = new File([data.Body], fileKey);
                  this.writeInitialData(file,file_date);
                }
              });
            }
          }
        });
      });
    },
    searchFiles(){
      document.getElementById("assetsFieldHandle_v").click()
    },
    onChange() {
      var files_arr = [...this.$refs.file.files];
      files_arr = _.sortBy(files_arr, (obj) => parseInt(obj.name, 10));
      this.filelist = files_arr;
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-orange')) {
        event.currentTarget.classList.remove('bg-white');
        event.currentTarget.classList.add('bg-orange');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
      this.onChange();
    },
    readFiles(){
      for(var i = 0; i < this.filelist.length; i++){
        var file_name = this.filelist[i].name.toLowerCase()
        if(this.filelist[i].type.indexOf('text/csv') !== -1 && file_name.indexOf('vendor') !== -1){
          this.writeInitialData(this.filelist[i], Date.now())
        }else{
          this.$store.state.isLoading = false
          this.remove(this.filelist[i])
          alert('Invalid File(s)')
        }
      }
    },
    writeInitialData(file,file_date){
      var reader = new FileReader();
      var self = this
      self.$store.state.isLoading = true
      reader.readAsBinaryString(file);

      reader.onload = function(e) {
        var data = e.target.result;
        var parsedData = Papa.parse(data, {
          header: true, // Set header to true to parse the first row as headers
          dynamicTyping: false, // Disable automatic type casting
          skipEmptyLines: true, // Skip empty lines
        });

        var json = parsedData.data;

        if (json.length === 0) {
          self.$store.state.isLoading = false;
          alert('Invalid File(s)');
          return;
        }

        setTimeout(function(){
          self.updateDatabase(json,file_date)
        },1000)
      }
    },
    updateDatabase(json,file_date){
      var self = this
      var db_object = {}
      var current_db_vendors_clone = self.db.vendors ? JSON.parse(JSON.stringify(self.db.vendors)) : {}

      for(var i = 0; i < json.length; i++){
        var row_data = json[i]
        var vendor_info = {
            'name': row_data['Vendor Name'] ? row_data['Vendor Name'] : '',
            'number': row_data['Vendor Number'] ? String(parseFloat(row_data['Vendor Number'])) : '',
            'po_confirmation_email': row_data['PO Confirmation Email'] ? row_data['PO Confirmation Email'] : 'email missing',
            'eta_inquiry_email': row_data['ETA Inquiry Email'] ? row_data['ETA Inquiry Email'] : 'email missing',
            'escalation_email': row_data['Escalation Email'] ? row_data['Escalation Email'] : 'email missing',
            'self_confirm_po': row_data['Self-Confirm Vendor POs?'] === 'Y' ? true : false,
            'self_track_eta': row_data['Self-Track Vendor ETAs?'] === 'Y' ? true : false,
            'page': parseFloat(row_data['Vendor Number']) + '937' + String(Math.floor(100000 + Math.random() * 900000)) + String(Math.floor(100000 + Math.random() * 900000)),
            'upload_date': Date.now(),
            'default_eta_days': 10,
            'upload_by': self.user
        }

        db_object['vendor_' + vendor_info['number']] = vendor_info

        if(!current_db_vendors_clone['vendor_' + vendor_info['number']]){ // new vendor
          firebase.database().ref('vendors').child('vendor_' + vendor_info['number']).set(db_object['vendor_' + vendor_info['number']])
          self.setVendorPageMapping(vendor_info['number'],vendor_info['page'])
        }else{ // existing vendor
          firebase.database().ref('vendors').child('vendor_' + vendor_info['number']).child('name').set(db_object['vendor_' + vendor_info['number']].name)
          firebase.database().ref('vendors').child('vendor_' + vendor_info['number']).child('po_confirmation_email').set(db_object['vendor_' + vendor_info['number']].po_confirmation_email)
          firebase.database().ref('vendors').child('vendor_' + vendor_info['number']).child('eta_inquiry_email').set(db_object['vendor_' + vendor_info['number']].eta_inquiry_email)
          firebase.database().ref('vendors').child('vendor_' + vendor_info['number']).child('escalation_email').set(db_object['vendor_' + vendor_info['number']].escalation_email)
          firebase.database().ref('vendors').child('vendor_' + vendor_info['number']).child('self_confirm_po').set(db_object['vendor_' + vendor_info['number']].self_confirm_po)
          firebase.database().ref('vendors').child('vendor_' + vendor_info['number']).child('self_track_eta').set(db_object['vendor_' + vendor_info['number']].self_track_eta)
        }
      }
      firebase.database().ref('last_vendor_file_upload').set(file_date)
      firebase.database().ref('last_vendor_file_pull').set(Date.now())
      self.filelist = []
      self.$store.state.isLoading = false
    },
    setVendorPageMapping(vendor_number,random_page_key){
      firebase.database().ref('vendor_page_mapping').child(random_page_key).set('vendor_'+vendor_number)
    }
  }
}
</script>

<style scoped>
  .dropzone{
    width: 100%;
    height: 50px;
    border: 2px dashed orange;
    border-radius: 4px;
  }
  .bg-white{
    background: white;
  }
  .bg-orange{
    background: orange;
  }
</style>
