import axios from 'axios'
import firebase from 'firebase/compat/app'
import moment from 'moment-business-days'
import { emailMixin } from '../mixins/emailMixin.js'
import { mapGetters, mapActions } from 'vuex'

export const poConfirmationFollowupEtaRequest = {
  mixins: [emailMixin],
  methods:{
    buildEmailsList(){
      var self = this
      var purchase_orders;
      var vendor_emails = {}
      self.$store.state.isLoading = true

      firebase.database().ref('purchase_orders').orderByChild('action_po_complete').equalTo('N').once('value', function(po_snapshot) {
        var time_current_MILLISECONDS = Date.now()
        var today_formatted = moment(time_current_MILLISECONDS).format('YYYY-MM-DD')
        purchase_orders = po_snapshot.val()

        for(var po_number in purchase_orders){
          var po = purchase_orders[po_number]
          var items = purchase_orders[po_number].items
          var vendor_info = self.$store.state.db.vendors['vendor_'+po.vendor_number]
          if(po.action_vendor_po_conf_response === 'Pending'){
            var po_time_between_today_and_last_requested_MILLISECONDS = time_current_MILLISECONDS - po.po_confirmation_last_requested
            var hours_since_last_request = po_time_between_today_and_last_requested_MILLISECONDS / (3600 * 1000);

            if(!vendor_info.self_confirm_po && hours_since_last_request >= 20 && po.action_po_conf_request_cnt === 1){
              if(vendor_emails[vendor_info.name]){
                vendor_emails[vendor_info.name].purchase_order_reminders.push(po_number)
              }else{
                vendor_emails[vendor_info.name] = {purchase_order_reminders: [po_number], eta_requests: [], escalate: false, vendor_info: vendor_info}
              }
            }
            if(!vendor_info.self_confirm_po && hours_since_last_request >= 20 && po.action_po_conf_request_cnt >= 2 && po.action_po_conf_request_cnt < 5){
              if(vendor_emails[vendor_info.name]){
                vendor_emails[vendor_info.name].purchase_order_reminders.push(po_number)
                vendor_emails[vendor_info.name].escalate = true
              }else{
                vendor_emails[vendor_info.name] = {purchase_order_reminders: [po_number], eta_requests: [], escalate: true, vendor_info: vendor_info}
              }
            }
          }

          if(po.action_vendor_po_conf_response === 'Confirmed'){
            for(var item_key in items){
              var item = items[item_key]
              if(item.line_complete === 'N' && item.eta !== 'UTP' && item.eta !== 'TBD' && !vendor_info.self_track_eta){

                var item_last_requested_date_in_milliseconds = item.eta_last_requested ? item.eta_last_requested : false
                var item_time_between_today_and_last_requested_MILLISECONDS = time_current_MILLISECONDS - item_last_requested_date_in_milliseconds
                var hours_since_last_request = item_time_between_today_and_last_requested_MILLISECONDS / (3600 * 1000);
                var item_eta_formatted = moment(new Date(item.eta)).format('YYYY-MM-DD')
                var item_past_due = moment(item_eta_formatted).isBefore(today_formatted);
                
                if(item_past_due && hours_since_last_request >= 20){
                  if(vendor_emails[vendor_info.name]){
                    vendor_emails[vendor_info.name].eta_requests.push(po_number+':::'+item_key)
                  }else{
                    vendor_emails[vendor_info.name] = {purchase_order_reminders: [], eta_requests: [po_number+':::'+item_key], escalate: false, vendor_info: vendor_info}
                  }
                  if(item.eta_escalation_requests_cnt > 0){
                    vendor_emails[vendor_info.name].escalate = true
                  }
                }
              }

              // TBD
              if(item.line_complete === 'N' && item.eta === 'TBD' && !vendor_info.self_track_eta){
                var item_time_between_today_and_last_provided_milliseconds = time_current_MILLISECONDS - item.eta_last_provided
                var hours_since_last_request = item_time_between_today_and_last_provided_milliseconds / (3600 * 1000);
                if(hours_since_last_request >= 20){
                  if(vendor_emails[vendor_info.name]){
                    vendor_emails[vendor_info.name].eta_requests.push(po_number+':::'+item_key)
                  }else{
                    vendor_emails[vendor_info.name] = {purchase_order_reminders: [], eta_requests: [po_number+':::'+item_key], escalate: false, vendor_info: vendor_info}
                  }
                  if(item.eta_escalation_requests_cnt > 0){
                    vendor_emails[vendor_info.name].escalate = true
                  }                  
                }
              }
            }
          }
        }

        setTimeout(function(){
          var today_string = moment(Date.now()).format('YYYY-MM-DD')
          self.$store.state.isLoading = false
          firebase.database().ref('last_po_confirmations_and_eta_requests_triggered').set(Date.now())
          firebase.database().ref('po_confirmations_and_eta_requests_triggered_emails_built').child(today_string).push({emails: JSON.stringify(vendor_emails)})
        },5000)

        var email_index = 0
        for(var vendor_name in vendor_emails){
          var vendor_email_needs = vendor_emails[vendor_name]
          var needs_purchase_order_confirmations = vendor_email_needs.purchase_order_reminders.length > 0
          var needs__item_etas = vendor_email_needs.eta_requests.length > 0
          var needs_escalated = vendor_email_needs.escalate
          var po_confirmation_email_arr = vendor_email_needs.vendor_info.po_confirmation_email !== 'email missing' ? vendor_email_needs.vendor_info.po_confirmation_email.replace(/ /g, '').toLowerCase().split(';') : []
          var eta_inquiry_email_arr = vendor_email_needs.vendor_info.eta_inquiry_email !== 'email missing' ? vendor_email_needs.vendor_info.eta_inquiry_email.replace(/ /g, '').toLowerCase().split(';') : []
          var escalation_email_arr = needs_escalated && vendor_email_needs.vendor_info.escalation_email !== 'email missing' ? vendor_email_needs.vendor_info.escalation_email.replace(/ /g, '').toLowerCase().split(';') : []
          var to_address_arr = [...new Set([].concat(po_confirmation_email_arr,eta_inquiry_email_arr,escalation_email_arr))]

          email_index++
          (function (email_index,to_address_arr,vendor_email_needs) {
              setTimeout(function () {

                if(to_address_arr.length){
                  self.sendEmail(to_address_arr,vendor_email_needs)
                }

              }, 1000 * email_index);
          })(email_index,to_address_arr,vendor_email_needs);
        }
      })


    },
    sendEmail(to_email_arr,detail){

      var subject = 'Riverhawk Industrial Supply - '
      var html = ''

      if (detail.purchase_order_reminders.length && detail.eta_requests.length){
        subject += 'PO Confirmation and ETA Requests'
        html += 'Hello,<br><br>Please confirm our purchase orders and provide ETAs for requested items'
      }else if (detail.purchase_order_reminders.length && !detail.eta_requests.length){
        subject += 'PO Confirmation Requests'
        html += 'Hello,<br><br>Please confirm our purchase orders'
      }else if (detail.eta_requests.length && !detail.purchase_order_reminders.length){
        subject += 'ETA Requests'
        html += 'Hello,<br><br>Please provide ETAs for requested items'
      }

      html += ' by <a href="' + 'https://riverhawknest.com/vendor/' + detail.vendor_info.page + '">clicking here</a>.'
      html += ' DO NOT REPLY.<br><br>Thanks,<br>Purchasing Team<br>Riverhawk Industrial Supply'

      var from_email = 'apps@riverhawkindustrialsupply.com'

      var encodedSubject = encodeURIComponent(subject);
      var encodedHtml = encodeURIComponent(html);

      var url = 'https://us-central1-riverhawk-nest-production.cloudfunctions.net/sendNestEmail' + '?to=' + to_email_arr.join(';') + '&from=' + from_email +'&subject='+encodedSubject + '&html='+encodedHtml

      var requested_timestamp = Date.now()

      var today_string = moment(requested_timestamp).format('YYYY-MM-DD')

      axios.post(url, {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'})
      .then(function (response) {
        if(response.data.success){

          for(var p = 0; p < detail.purchase_order_reminders.length; p++){
            var po_number = detail.purchase_order_reminders[p]
            firebase.database().ref('purchase_orders').child(po_number + '/action_vendor_po_conf_response').set('Pending')
            firebase.database().ref('purchase_orders').child(po_number + '/po_confirmation_last_requested').set(requested_timestamp)
            firebase.database().ref('purchase_orders').child(po_number + '/po_confirmation_request_history').push({'requested_by': 'automation', 'requested_date': requested_timestamp})
            firebase.database().ref('purchase_orders').child(po_number + '/action_po_conf_request_cnt').transaction(function(requests) { return (requests || 0) + 1 })
          }

          for(var i = 0; i < detail.eta_requests.length; i++){
            var item_po_number = detail.eta_requests[i].split(':::')[0]
            var item_key = detail.eta_requests[i].split(':::')[1]
            firebase.database().ref('purchase_orders').child(item_po_number).child('items').child(item_key).child('pending_eta_request').set(true)
            firebase.database().ref('purchase_orders').child(item_po_number).child('items').child(item_key).child('eta_last_requested').set(requested_timestamp)
            firebase.database().ref('purchase_orders').child(item_po_number).child('items').child(item_key).child('eta_escalation_requests').push({'requested_by': 'automation', 'requested_date': requested_timestamp, 'source': 'automation'})
            firebase.database().ref('purchase_orders').child(item_po_number).child('items').child(item_key).child('eta_escalation_requests_cnt').transaction(function(requests) { return (requests || 0) + 1 })
          }

          firebase.database().ref('email_success_log').child(today_string).push({email: detail, timestamp: requested_timestamp, method: 'po_confirmation_reminders_and_eta_requests_follow_up_from_dashboard'}).then(function(){
            console.log(response.data.success.message)
          })

        }else{
          console.log(response)
          firebase.database().ref('email_failure_log').child(today_string).push({email: detail, timestamp: requested_timestamp, method: 'po_confirmation_reminders_and_eta_requests_follow_up_from_dashboard', error: response})
        }
      })
      .catch(function (error) {
        console.log(error.message)
        firebase.database().ref('email_failure_log').child(today_string).push({email: detail, timestamp: requested_timestamp, method: 'po_confirmation_reminders_and_eta_requests_follow_up_from_dashboard', error: error.message})
      });
    }
  }
}
