<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">

      <v-card-text>
        <v-card-title style="margin-left:-15px;color:gray">{{edit ? 'Edit' : 'Add'}} User</v-card-title>
          <v-form v-model="valid">

            <v-text-field v-if="!edit" :rules="!edit ? emailRules : false" variant="solo" density="compact" label="EMAIL" placeholder="EMAIL" v-model="user_object.email"></v-text-field>

            <v-text-field v-if="!edit" :rules="!edit ? passwordRules : false" variant="solo" density="compact" label="PASSWORD" placeholder="PASSWORD" v-model="user_object.password"></v-text-field>

            <v-text-field :rules="requiredRules" variant="solo" density="compact" label="FIRST NAME" placeholder="FIRST NAME" v-model="user_object.first_name"></v-text-field>
            <v-text-field :rules="requiredRules" variant="solo" density="compact" label="LAST NAME" placeholder="LAST NAME" v-model="user_object.last_name"></v-text-field>
            <v-text-field :rules="requiredRules" variant="solo" density="compact" label="INITIALS" placeholder="INITIALS" v-model="user_object.initials"></v-text-field>
            <span style="color:gray">ROLES</span>
          <div v-for="role in ['Purchasing Assistant', 'Purchaser', 'Sales', 'Administrator']">
            <v-checkbox :rules="rolesRules" color="#ff7214" variant="solo" density="compact" :hide-details="role !== 'Administrator'" v-model="user_object.roles" :label="role" :value="role"></v-checkbox>
          </div>
        </v-form>
      </v-card-text>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveNewUser()" :disabled="!edit ? !valid : !editValid">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'UserPopUp',
  components: {},
  mixins: [],
  props:{
    edit: String,
  },
  data(){
    return{
      dialog: true,
      valid: false,
      user_object: {
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        initials: '',
        roles: []
      },
      emailRules: [
        // v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        (v) => !v || /^[\w.-]+@\w+[\w.-]*\.\w{2,}$/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 6 || 'Password must be at least 6 characters.'
      ],
      requiredRules: [
        v => !!v || 'Required'
      ],
      rolesRules: [
        v => v.length > 0 || 'At least one role is required.'
      ]
    }
  },
  beforeMount(){
    if(this.edit){
      var current_user = JSON.parse(JSON.stringify( this.db.users[this.edit] ))
      this.user_object.first_name = current_user.first_name
      this.user_object.last_name = current_user.last_name
      this.user_object.initials = current_user.initials
      this.user_object.roles = current_user.roles
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    editValid(){
      return this.user_object.first_name !== '' && this.user_object.last_name && this.user_object.roles.length > 0
    }
  },
  methods:{
    saveNewUser(){
        // https://us-central1-riverhawk-nest-production.cloudfunctions.net/addUser?email=fin1@test.com&password=password123&first_name=chris&last_name=lee&initials=cwf&roles=["test","test2","test3"]&added_by=xazjC3jcy7eL6SJt8sU0wFEC6XJ3
        var self = this
        self.user_object.added_by = this.user

        if(!self.edit){
          var url = 'https://us-central1-riverhawk-nest-production.cloudfunctions.net/addUser?'
                    +'&email='+self.user_object.email
                    +'&password='+self.user_object.password
                    +'&first_name='+self.user_object.first_name
                    +'&last_name='+self.user_object.last_name
                    +'&initials='+self.user_object.initials
                    +'&roles='+JSON.stringify(self.user_object.roles)
                    +'&added_by='+self.user_object.added_by

          var headers = {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'}

          self.$store.state.isLoading = true

          axios.post(url, {headers})
          .then(function (response) {
            self.$store.state.isLoading = false
            if(response.data.success){
              alert(response.data.success.message)
              self.$emit('close')
            }else if(response.data.error){
              alert(response.data.error.message)
            }else{
              alert('Unknown error adding user.')
            }
          })
          .catch(function (error) {
            alert(error)
            self.$store.state.isLoading = false
          });
        }else{
          self.$store.state.isLoading = true

          firebase.database().ref('users').child(self.edit).update({'modified': Date.now(), 'modified_by': self.user, 'first_name': self.user_object.first_name, 'last_name': self.user_object.last_name, 'initials': self.user_object.initials, 'roles': self.user_object.roles}).then(function(){
            self.$store.state.isLoading = false
            alert('User successfully updated.')
            self.$emit('close')
          })

        }

    }
  }
}
</script>

<style>

</style>


