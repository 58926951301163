<template>
  <div class="purchase_orders_master">
    <div style="font-size: 22px; color: gray;padding:10px">Purchase Orders Master (Incomplete POs Only)</div>

    <div v-if="purchase_orders_table_data.length">
      <v-row style="width:750px;margin:0px 0px">
        <v-col cols="6">
          <div style="width:350px;">
            <v-text-field label="Search Purchase Orders..." variant="solo" density="comfortable" hide-details="true" @input="debouncedSearch($event)"></v-text-field>
          </div>
        </v-col>
      </v-row>

      <div class="table-container">
          <Vue3EasyDataTable
            :headers="po_headers"
            :items="purchase_orders_table_data"
            :search-value="searchValuePurchaseOrders"
            :table-height="table_height"
            :rows-per-page="250"
            :rows-items="[250, 500, 1000, 5000]"
            :hide-rows-per-page="false"
            :hide-footer="false"
          >

          <template #item-po_number="item">
            <span style='color:#ff7214;cursor:pointer' @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
          </template>

          <template #item-vendor_name="item">
            <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" style="color:#ff7214">{{item.vendor_name}}</router-link>
          </template>

          <template #item-po_date_sortable="item">
            {{item.po_date_formatted}}
          </template>


          <template #item-upload_date_sortable="item">
            {{item.upload_date_formatted}}
          </template>

          <template #item-shared_notes="item">
            <div v-for="n in item.shared_notes" style="border-bottom:1px dashed;margin-top:5px;margin-bottom:5px;line-height:100%">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
            </div>
          </template>

          <template #item-internal_notes="item">
            <div v-for="n in item.internal_notes" style="border-bottom:1px dashed;margin-top:5px;margin-bottom:5px;line-height:100%">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
            </div>
          </template>

          <template #item-po_confirmation_status="item">
            <div>{{item.po_confirmation_status}}</div>
            <div style="font-style:italic;font-size:8px;color:gray">{{item.vendor_po_confirmation_email}}</div>
            <div style="font-style:italic;font-size:8px;color:gray" v-if="item.vendor_auto_confirms">auto-confirm vendor</div>
          </template>

        </Vue3EasyDataTable>
      </div>
    </div>
    <PurchaseOrderDetailPopUp v-if="po_detail" :po='po_for_component' @close="closePurchaseOrderDetailPopup()" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Moment from 'moment-business-days';
import PurchaseOrderDetailPopUp from '@/components/PurchaseOrderDetailPopUp.vue';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { getDatabase, ref, query, orderByChild, equalTo, onChildAdded, onChildChanged, onChildRemoved } from 'firebase/database';
import { debounce } from 'lodash';

export default {
  name: 'PurchaseOrdersMaster',
  components: { PurchaseOrderDetailPopUp, Vue3EasyDataTable },
  data() {
    return {
      po_detail: false,
      po_for_component: null,
      table_height: 400,
      searchValuePurchaseOrders: '',
      po_headers: [
        { text: "PO Number", value: "po_number", sortable: true },
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "PO Date", value: "po_date_sortable", sortable: true },
        { text: "Upload Date", value: "upload_date_sortable", sortable: true },
        { text: "Buyer Name", value: "buyer_name", sortable: true },
        { text: "Shared Notes", value: "shared_notes", sortable: false },
        { text: "Internal Notes", value: "internal_notes", sortable: false },
        { text: "PO Confirmation Status", value: "po_confirmation_status", sortable: true }
      ],
      purchase_orders_table_data: [],
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){this.$router.push('/');return}
    this.$store.state.isLoading = true
  },
  mounted() {
    setTimeout(() => {
      const db = getDatabase();
      const purchaseOrdersRef = ref(db, 'purchase_orders');
      const purchaseOrders = query(purchaseOrdersRef, orderByChild('action_po_complete'), equalTo('N'));

      this.onChildAddedListener = onChildAdded(purchaseOrders, (snapshot) => {
        const po = snapshot.val();
        po.id = snapshot.key;
        po.po_confirmation_status = this.po_confirmation_status(po)
        po.po_date_sortable = Moment(po.po_date, 'M/D/YY').format('YYYY-MM-DD');
        po.po_date_formatted = Moment(po.po_date, 'M/D/YY').format('MM/DD/YY');

        po.upload_date_sortable = Moment(po.upload_date).format('YYYY-MM-DD');
        po.upload_date_formatted = Moment(po.upload_date).format('MM/DD/YY');

        po.vendor_page = this.db.vendors['vendor_'+po.vendor_number] ? this.db.vendors['vendor_'+po.vendor_number].page : ''
        po.vendor_po_confirmation_email = this.db.vendors['vendor_'+po.vendor_number].po_confirmation_email
        po.vendor_auto_confirms = this.db.vendors['vendor_'+po.vendor_number].self_confirm_po
        this.purchase_orders_table_data.push(po)
        this.$store.state.isLoading = false;
      });

      this.onChildChangedListener = onChildChanged(purchaseOrders, (snapshot) => {
        const po = snapshot.val();
        po.id = snapshot.key;
        po.po_confirmation_status = this.po_confirmation_status(po)
        po.po_date_sortable = Moment(po.po_date, 'M/D/YY').format('YYYY-MM-DD');
        po.po_date_formatted = Moment(po.po_date, 'M/D/YY').format('MM/DD/YY');
        po.vendor_page = this.db.vendors['vendor_'+po.vendor_number] ? this.db.vendors['vendor_'+po.vendor_number].page : ''
        po.vendor_po_confirmation_email = this.db.vendors['vendor_'+po.vendor_number].po_confirmation_email
        po.vendor_auto_confirms = this.db.vendors['vendor_'+po.vendor_number].self_confirm_po

        const index = this.purchase_orders_table_data.findIndex(i => i.id === po.id);
        if (index !== -1) {
          this.purchase_orders_table_data.splice(index, 1, po);
        }
      });

      this.onChildRemovedListener = onChildRemoved(purchaseOrders, (snapshot) => {
        const id = snapshot.key;
        const index = this.purchase_orders_table_data.findIndex((po) => po.id === id);
        if (index !== -1) {
          this.purchase_orders_table_data.splice(index, 1);
        }
      });
    }, 1000);
  },
  beforeUnmount() {
    if (this.onChildAddedListener) {
      this.onChildAddedListener();
    }
    if (this.onChildChangedListener) {
      this.onChildChangedListener();
    }
    if (this.onChildRemovedListener) {
      this.onChildRemovedListener();
    }
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status']),
  },
  methods: {
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 260
    },
    debouncedSearch: debounce(function(event) {
      this.searchValuePurchaseOrders = event.target._value
    }, 500),
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    openPurchaseOrderDetailPopup(po){
      this.po_for_component = po
      this.po_detail = true
    },
    closePurchaseOrderDetailPopup(){
      this.po_for_component = null
      this.po_detail = false
    },
  },
}
</script>

<style scoped>
  .table-container{
    position: relative;
    top: 5px;
    margin:0px 10px;
  }
</style>

