<template>
  <div class="users-administration">
    <div style="font-size: 22px; color: gray;padding:10px">Users Administration</div>
    <div style="margin:0px 10px">

      <v-checkbox color="#ff7214" variant="solo" density="compact" hide-details="true" v-model="include_deactived_users" label="Include Deactivated Users"></v-checkbox>

      <Vue3EasyDataTable
        :headers="headers"
        :items="users_table_data"
        :sort-by="sortBy"
        :sort-type="sortType"
        :rows-per-page="2000"
        :hide-rows-per-page="true"
        :hide-footer="true"
        :table-height="table_height"
      >

        <template #item-date_created_sortable="item">
          {{item.date_created_formatted}}
        </template>

        <template #item-edit="item">
          <!-- <v-list-item-icon v-bind="attrs" v-on="on"> -->
            <v-icon style="cursor:pointer;color:#ff7214" @click="editUser(item.id)">mdi-pencil</v-icon>
          <!-- </v-list-item-icon> -->
        </template>

        <template #item-disable_enable="item">
          <!-- <v-list-item-icon v-bind="attrs" v-on="on" v-if="!item.disabled"> -->
            <v-icon style="cursor:pointer;color:#ff7214" @click="disableUser(item.id)" v-if="!item.disabled && item.id !== user">mdi-trash-can</v-icon>
          <!-- </v-list-item-icon> -->
          <!-- <v-list-item-icon v-bind="attrs" v-on="on" v-if="item.disabled"> -->
            <v-icon style="cursor:pointer;color:#ff7214" @click="enableUser(item.id)" v-if="item.disabled">mdi-restore</v-icon>
          <!-- </v-list-item-icon> -->
        </template>

      </Vue3EasyDataTable>

      <v-btn style="background:#ff7214;color:white; margin-top:5px" @click="addNewUser()">+ ADD USER</v-btn>
    </div>

    <UserPopUp v-if="add_user || edit_user" :edit="edit_id" @close="closeUserPopup()" />
  </div>
</template>

<script>

import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import UserPopUp from '@/components/UserPopUp.vue'
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'UsersAdministration',
  components: {Vue3EasyDataTable,UserPopUp},
  data(){
    return {
      add_user: false,
      edit_user: false,
      edit_id: '',
      include_deactived_users: false,
      sortBy: 'name',
      sortType: 'desc',
      table_height: 400,
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Initials", value: "initials", sortable: true },
        { text: "Roles", value: "roles_flat", sortable: false },
        { text: "E-Mail", value: "email", sortable: true },
        { text: "Created By", value: "created_by", sortable: true },
        { text: "Created", value: "date_created_sortable", sortable: true },
        { text: "", value: "edit", sortable: false },
        { text: "", value: "disable_enable", sortable: false }
      ]
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    users_table_data(){
      var arr = []
      var users = JSON.parse(JSON.stringify( this.db.users))
      for(var u in users){
        var user = users[u]

        user.id = u
        user.name = user.first_name + ' ' + user.last_name
        user.date_created_sortable = user.created_date
        user.date_created_formatted = Moment(user.created_date).format('MM/DD/YY')
        user.roles_flat = user.roles
        user.created_by = users[user.added_by].first_name + ' ' + users[user.added_by].last_name
        if(!user.disabled || this.include_deactived_users){
          arr.push(user)
        }
      }
      return arr
    }
  },
  methods: {
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 250
    },
    addNewUser(){
      this.add_user = true
    },
    editUser(user){
      this.edit_user = true
      this.edit_id = user
    },
    closeUserPopup(){
      this.add_user = false
      this.edit_user = false
      this.edit_id = ''
    },
    disableUser(uid){
      var self = this
      var url = 'https://us-central1-riverhawk-nest-production.cloudfunctions.net/disableUser?'
                +'&uid='+uid
      var headers = {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'}

      var confirm_disable = confirm('Are you sure you want to disable access for ' + self.db.users[uid].first_name + ' ' + self.db.users[uid].last_name + '?')

      if(!confirm_disable){return}

      self.$store.state.isLoading = true

      axios.post(url, {headers})
      .then(function (response) {
        self.$store.state.isLoading = false
        if(response.data.success){
          alert(response.data.success.message)
        }else if(response.data.error){
          alert(response.data.error.message)
        }else{
          alert('Unknown error disabling user.')
        }
      })
      .catch(function (error) {
        self.$store.state.isLoading = false
        alert('Unknown error disabling user.')
      });
    },
    enableUser(uid){
      var self = this
      var url = 'https://us-central1-riverhawk-nest-production.cloudfunctions.net/enableUser?'
                +'&uid='+uid
      var headers = {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'}
      var confirm_disable = confirm('Are you sure you want to reactivate access for ' + self.db.users[uid].first_name + ' ' + self.db.users[uid].last_name + '?')

      if(!confirm_disable){return}

      self.$store.state.isLoading = true

      axios.post(url, {headers})
      .then(function (response) {
        self.$store.state.isLoading = false
        if(response.data.success){
          alert(response.data.success.message)
        }else if(response.data.error){
          alert(response.data.error.message)
        }else{
          alert('Unknown error reactivating user.')
        }
      })
      .catch(function (error) {
        self.$store.state.isLoading = false
        alert('Unknown error reactivating user.')
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .inactive{
    color: #C0C0C0;
  }
</style>
