<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">

      <v-card-text>

        <v-card-title style="margin-left:-15px">Add {{type.charAt(0).toUpperCase() + type.slice(1)}} Note for PO {{po}}</v-card-title>

        <v-text-field variant="outlined" placeholder="Your Name" v-model="submitted_by" v-if="!user"></v-text-field>

        <v-textarea variant="outlined" height="20" :placeholder="type.charAt(0).toUpperCase() + type.slice(1) + ' Note'" v-model="note"></v-textarea>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveNote()" :disabled="submitted_by.trim() === '' || note.trim() === ''">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'

export default {
  name: 'NotePurchaseOrderPopUp',
  components: {},
  mixins: [],
  props:{
    po: String,
    type: String
  },
  data(){
    return{
      dialog: true,
      note: '',
      submitted_by: ''
    }
  },
  beforeMount(){
    if(this.isvendor){

    }
    if(this.user){
      this.submitted_by = this.db.users[this.user].email
    }
  },
  computed: {
    ...mapGetters(['user','db']),
  },
  methods:{
    saveNote(){
      var self = this
      firebase.database().ref('purchase_orders').child(self.po).child(self.type+'_notes').push({note: self.note, submitted_by: self.submitted_by, submitted_date: Date.now()}).then(function(){
        self.$emit('close')
      })
    }
  }
}
</script>

<style>

</style>


