<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">

      <v-card-text>

        <v-card-title style="margin-left:-15px">Backorder Inquiry for {{this.db.backorders[backorder].product_number}}</v-card-title>

        <v-text-field variant="outlined" placeholder="*Your Name" label="*Your Name" v-model.trim="name" required></v-text-field>
        <v-text-field variant="outlined" placeholder="*Your Email" label="*Your Email" v-model.trim="email" required></v-text-field>
        <v-textarea variant="outlined" height="20" :placeholder="'*Tell us what you’d like to know about this product'" label="*Tell us what you’d like to know about this product" v-model.trim="note" required></v-textarea>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveNote()" :disabled="!name || !email || !note">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import { emailMixin } from '../mixins/emailMixin.js'

export default {
  name: 'BackorderInquiryPopUp',
  components: {},
  mixins: [emailMixin],
  props:{
    backorder: String,
  },
  data(){
    return{
      dialog: true,
      name: '',
      email: '',
      note: ''
    }
  },
  beforeMount(){

  },
  computed: {
    ...mapGetters(['user','db']),
  },
  methods:{
    saveNote(){
      var self = this
      var backorder_info = self.db.backorders[self.backorder]
      var account_specialist_code = self.db.customers['customer_'+backorder_info.customer_number].account_specialist
      var to;
      var cc;

      for(var u in self.db.users){
        if(self.db.users[u].initials === account_specialist_code){
          to = self.db.users[u].email
        }

        if(self.db.users[u].initials === backorder_info.salesman_code){
          cc = self.db.users[u].email
        }
      }

      if(!to){
        to = 'sales@riverhawkindustrialsupply.com'
      }

      if(cc){
        to += ';'+cc
      }

      var e = {
        to: to,
        customer: backorder_info.customer_name + ' (' + backorder_info.customer_number + ')',
        provided_email: self.email,
        provided_name: self.name,
        provided_note: self.note,
        order_number: backorder_info.order_number,
        product_number: backorder_info.product_number
      }

      self.customerInquireEmail(e)

      firebase.database().ref('backorders').child(self.backorder).child('inquiries').push({note: self.note, submitted_by_name: self.name, submitted_by_email: self.email, submitted_date: Date.now()}).then(function(){
        self.$emit('close')
      })
    }
  }
}
</script>

<style>

</style>


