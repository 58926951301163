<template>

  <div class="collections_candidates">
    <div style="font-size: 22px; color: gray;padding:10px">Collections Candidates</div>
    <div v-if="ar_table_data.length" style="margin:10px">
      <v-row>
        <v-col cols="12">
          <v-card elevation="12">
            <v-card-title>31+ Days Past Due</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <div>
                    <v-text-field label="Search..." variant="solo" density="compact" hide-details="true" @input="debouncedSearch($event)"></v-text-field>
                  </div>
                </v-col>
              </v-row>
              
              <div style="padding-top:15px;color:gray"></div>

              <Vue3EasyDataTable
                :headers="headers"
                :items="ar_table_data"
                :search-value="searchValue"
                :sort-by="sortBy"
                :sort-type="sortType"
                :table-height="table_height"
                :rows-per-page="250"
                :rows-items="[250, 500, 1000, 5000]"
                :hide-rows-per-page="false"
                :hide-footer="false"
              >

              <template #item-customer_name="item">
              <div style="line-height:150%">
                <router-link :to="{ name: 'customer-ar', params: {customerarpageid: item.customer_number}}" target="_blank" style="color:#ff7214">{{item.customer_name}}</router-link>
                </div>
              </template>

              <template #item-ar_balance="item">
                {{formatCurrency(item.ar_balance)}}
              </template>

              <template #item-open_order_balance="item">
                {{formatCurrency(item.open_order_balance)}}
              </template>

              <template #item-credit_limit="item">
                {{formatCurrency(item.credit_limit)}}
              </template>

              <template #item-current="item">
                {{formatCurrency(item.current)}}
              </template>          

              <template #item-1_30="item">
                {{formatCurrency(item['1_30'])}}
              </template>      

              <template #item-31_60="item">
                {{formatCurrency(item['31_60'])}}
              </template>      

              <template #item-61_90="item">
                {{formatCurrency(item['61_90'])}}
              </template>   

              <template #item-91_over="item">
                {{formatCurrency(item['91_over'])}}
              </template>   

              <template #item-past_due_31_more_balance="item">
                <span :style="item['past_due_31_more_balance'] > 0 ? {backgroundColor: 'red', color: 'white', fontWeight: 'bold', padding: '0 10px', fontSize: '0.7rem'} : {}">
                  {{formatCurrency(item['past_due_31_more_balance'])}}
                </span>
              </template> 

              <template #item-days_since_last_reminder_sent="item">
                {{item.days_since_last_reminder_sent === 9999 ? 'never contacted' : item.days_since_last_reminder_sent}}
              </template>    

              <template #item-balance_change_since_last_requested_past_due_31="item">
                {{formatCurrency(item['balance_change_since_last_requested_past_due_31'])}}
              </template>   


              <template #item-send_reminder="item">
                <v-btn style="background:#ff7214;color:white;width:150px;height:20px;font-size:10px;margin:5px 0px" @click="sendReminder(item)">SEND REMINDER</v-btn>
              </template>

            </Vue3EasyDataTable>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { getDatabase, ref, query, orderByChild, equalTo, onChildAdded, onChildChanged, onChildRemoved } from 'firebase/database';
import { debounce } from 'lodash';

export default {
  name: 'Collections Candidates',
  components: {Vue3EasyDataTable},
  data(){
    return {
      filelist: [],
      searchValue: '',
      sortBy: 'customer_name',
      sortType: 'asc',
      table_height: 350,
      headers: [
        { text: "Customer Number", value: "customer_number", sortable: true },
        { text: "Customer Name", value: "customer_name", sortable: true },
        { text: "Account Executive", value: "account_executive", sortable: true },
        { text: "Credit Limit", value: "credit_limit", sortable: true },
        { text: "Credit Status", value: "credit_status", sortable: true },
        { text: "A/R Balance", value: "ar_balance", sortable: true },
        { text: "Current", value: "current", sortable: true },
        { text: "1 - 30", value: "1_30", sortable: true },
        { text: "31 - 60", value: "31_60", sortable: true },
        { text: "61 - 90", value: "61_90", sortable: true },
        { text: "91 & Over", value: "91_over", sortable: true },
        { text: "31+ Days Past Due Balance", value: "past_due_31_more_balance", sortable: true },
        { text: "31+ Days Past Due Balance Change Since Last Contact", value: "balance_change_since_last_requested_past_due_31", sortable: true },
        { text: "Days Since Last Contact", value: "days_since_last_reminder_sent", sortable: true },
        { text: "# Contacts Without Improvement ", value: "contacts_made_without_improvement", sortable: true },
        { text: "", value: "send_reminder", sortable: false },
      ],
      ar_table_data: []
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }    
    this.$store.state.isLoading = true
  },
  mounted() {
    setTimeout(() => {
      const db = getDatabase();
      const arRef = ref(db, 'accounts_receivable');

      this.addedIds = new Set();

      this.onChildAddedListener = onChildAdded(arRef, (snapshot) => {
        const ar = snapshot.val();
        ar.id = snapshot.key;
        ar.account_executive = this.db.customers[ar.id].salesperson_code;
        ar.credit_status = this.db.customers[ar.id].credit_status;
        ar.collections_inquiry_emails = this.db.customers[ar.id].collections_inquiry_emails
        ar.last_reminder_sent_formatted = ar.last_reminder_sent ? this.formatDate(ar.last_reminder_sent) : ''
        ar.days_since_last_reminder_sent = ar.last_reminder_sent ? Moment().diff(Moment(ar.last_reminder_sent), 'days') : 9999
        ar.past_due_31_more_balance = ar['31_60'] + ar['61_90'] + ar['91_over']
        ar.last_contacted_past_due_31_more_balance = ar.log_key_of_last_reminder_sent ? ar.reminders_sent[ar.log_key_of_last_reminder_sent].past_due_31_more_balance : 0
        ar.balance_change_since_last_requested_past_due_31 = ar.days_since_last_reminder_sent !== 9999 ? ar.past_due_31_more_balance - ar.last_contacted_past_due_31_more_balance : 0
        
        var contacts_made_without_improvement = 0
        let remindersArray = ar.reminders_sent ? Object.entries(ar.reminders_sent).map(([key, value]) => ({...value, id: key})) : []
        remindersArray.sort((a, b) => b.timestamp - a.timestamp);
        for(var i = 0; i < remindersArray.length; i++){
          var r = remindersArray[i]
          var reminder_balance = r.past_due_31_more_balance
          if(reminder_balance <= ar.past_due_31_more_balance && !r.archive){
            contacts_made_without_improvement++
          }
        }
        ar.contacts_made_without_improvement = contacts_made_without_improvement

        if(ar['31_60'] > 0 || ar['61_90'] > 0 || ar['91_over'] > 0) {
          if (!this.addedIds.has(ar.id)) {
            this.ar_table_data.push(ar);
            this.addedIds.add(ar.id);
          }
        }

        this.$store.state.isLoading = false;
      });

      this.onChildChangedListener = onChildChanged(arRef, (snapshot) => {
        const ar = snapshot.val();
        ar.id = snapshot.key;
        ar.account_executive = this.db.customers[ar.id].salesperson_code;
        ar.credit_status = this.db.customers[ar.id].credit_status;
        ar.collections_inquiry_emails = this.db.customers[ar.id].collections_inquiry_emails
        ar.last_reminder_sent_formatted = ar.last_reminder_sent ? this.formatDate(ar.last_reminder_sent) : ''
        ar.days_since_last_reminder_sent = ar.last_reminder_sent ? Moment().diff(Moment(ar.last_reminder_sent), 'days') : 9999
        ar.past_due_31_more_balance = ar['31_60'] + ar['61_90'] + ar['91_over']
        ar.last_contacted_past_due_31_more_balance = ar.log_key_of_last_reminder_sent ? ar.reminders_sent[ar.log_key_of_last_reminder_sent].past_due_31_more_balance : 0
        ar.balance_change_since_last_requested_past_due_31 = ar.days_since_last_reminder_sent !== 9999 ? ar.past_due_31_more_balance - ar.last_contacted_past_due_31_more_balance : 0

        var contacts_made_without_improvement = 0
        let remindersArray = ar.reminders_sent ? Object.entries(ar.reminders_sent).map(([key, value]) => ({...value, id: key})) : []
        remindersArray.sort((a, b) => b.timestamp - a.timestamp);
        for(var i = 0; i < remindersArray.length; i++){
          var r = remindersArray[i]
          var reminder_balance = r.past_due_31_more_balance
          if(reminder_balance <= ar.past_due_31_more_balance && !r.archive){
            contacts_made_without_improvement++
          }
        }
        ar.contacts_made_without_improvement = contacts_made_without_improvement

        const index = this.ar_table_data.findIndex((i) => i.id === ar.id);

        if(ar['31_60'] > 0 || ar['61_90'] > 0 || ar['91_over'] > 0) {
          if (index === -1) { // If it's not already in the array, add it.
            this.ar_table_data.push(ar);
            this.addedIds.add(ar.id);
          } else { // If it's already in the array, update it.
            this.ar_table_data.splice(index, 1, ar);
          }
        } else {
          if (index !== -1) { // If it doesn't meet the condition but it's in the array, remove it.
            this.ar_table_data.splice(index, 1);
            this.addedIds.delete(ar.id);
          }
        }
      });

      this.onChildRemovedListener = onChildRemoved(arRef, (snapshot) => {
        const id = snapshot.key;
        const index = this.ar_table_data.findIndex((ar) => ar.id === id);
        if (index !== -1) {
          this.ar_table_data.splice(index, 1);
          this.addedIds.delete(id);
        }
      });

    }, 1000);
  },
  beforeUnmount() {
    if (this.onChildAddedListener) {
      this.onChildAddedListener();
    }
    if (this.onChildChangedListener) {
      this.onChildChangedListener();
    }
    if (this.onChildRemovedListener) {
      this.onChildRemovedListener();
    }
  },
  computed: {
    ...mapGetters(['user','db'])
  },
  methods: {
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 300
    },
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value.replace(/\$/g, '')
    }, 500),
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    formatCurrency(value) {
      const absoluteValue = Math.abs(value);
      const sign = value < 0 ? "-" : "";
      const formattedValue = absoluteValue.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return `${sign}$${formattedValue}`;
    },
    sendReminder(customer){
      var self = this
      
      if(customer.collections_inquiry_emails.length === 0){
        alert('No customer email provided. Please add to Inform.')
      }
      
      var to = customer.collections_inquiry_emails
      var msg = 'Click \'OK\' to send a reminder to ' + customer.customer_name
      var reminder_sent = confirm(msg)

      if(reminder_sent){
        var timestamp = Date.now()      
        firebase.database().ref('accounts_receivable').child(customer.id).child('reminders_sent').push({timestamp: timestamp, sent_by: self.user, past_due_31_more_balance: customer.past_due_31_more_balance}).then(function(snapshot){
          firebase.database().ref('accounts_receivable').child(customer.id).child('log_key_of_last_reminder_sent').set(snapshot.key)
        })
        firebase.database().ref('accounts_receivable').child(customer.id).child('last_reminder_sent').set(timestamp)
        var past_due_str = 'Current: ' + this.formatCurrency(customer.current) + '\n1 - 30: ' + this.formatCurrency(customer['1_30']) + '\n31 - 60: ' + this.formatCurrency(customer['31_60']) + '\n61 - 90: ' + this.formatCurrency(customer['61_90']) + '\n91 & Over: ' + this.formatCurrency(customer['91_over'])       
        var body = encodeURIComponent('Dear Valued Customer,\n\n There are past due invoices on your account. In order to keep your account from being put on hold, please provide payment status as soon as possible.\n\n' + past_due_str + '\n\nPlease let me know if you need me to provide copies of any invoices.'+'\n\nThank you,\n'+this.db.users[this.user].first_name + ' '+this.db.users[this.user].last_name)
        window.location.href = 'mailto:' + to+'?subject=Riverhawk Past Due Invoices&body='+body
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table{
    font-size: 12px;
  }
  th {
    text-align: left;
    padding-right: 20px;
  }
  td {
    text-align: left;
    padding-right: 20px;
  }

  .table-container{
    position: relative;
    top: 5px;
    margin:0px 20px;
  }  
  .v-card .v-card-title{
    color: gray;
    font-size: 14px;
  }

</style>
