<template>
  <div class="ar_master">
    <div style="font-size: 22px; color: gray;padding:10px">Accounts Receivable Master</div>

    <div v-if="ar_table_data.length">
      <v-row style="width:750px;margin:0px 0px">
        <v-col cols="6">
          <div style="width:350px;">
            <v-text-field label="Search Accounts Receivable..." variant="solo" density="comfortable" hide-details="true" @input="debouncedSearch($event)"></v-text-field>
          </div>
        </v-col>
      </v-row>

      <div class="table-container">
          <Vue3EasyDataTable
            :headers="headers"
            :items="ar_table_data"
            :search-value="searchValue"
            :sort-by="sortBy"
            :sort-type="sortType"
            :table-height="table_height"
            :rows-per-page="250"
            :rows-items="[250, 500, 1000, 5000]"
            :hide-rows-per-page="false"
            :hide-footer="false"
          >

        <template #item-past_due_31_more_balance="item">
          <span :style="item['past_due_31_more_balance'] > 0 ? {backgroundColor: 'red', color: 'white', fontWeight: 'bold', padding: '0 10px', fontSize: '0.7rem'} : {}">
            {{formatCurrency(item['past_due_31_more_balance'])}}
          </span>
        </template> 

          <template #item-ar_balance="item">
            {{item.ar_balance !== 987654321 ? formatCurrency(item.ar_balance) : 'not in latest AR file'}}
          </template>

          <template #item-open_order_balance="item">
            {{item.open_order_balance !== 987654321 ? formatCurrency(item.open_order_balance) : 'not in latest AR file'}}
          </template>

          <template #item-credit_limit="item">
            {{formatCurrency(item.credit_limit)}}
          </template>

          <template #item-current="item">
            {{item.current !== 987654321 ? formatCurrency(item.current) : 'not in latest AR file'}}
          </template>          

          <template #item-1_30="item">
            {{formatCurrency(item['1_30'])}}
          </template>      

          <template #item-31_60="item">
            {{formatCurrency(item['31_60'])}}
          </template>      

          <template #item-61_90="item">
            {{formatCurrency(item['61_90'])}}
          </template>   

          <template #item-91_over="item">
            {{formatCurrency(item['91_over'])}}
          </template>   

        </Vue3EasyDataTable>
      </div>

    </div>
  </div>
</template>

<script>

import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { getDatabase, ref, query, orderByChild, equalTo, onChildAdded, onChildChanged, onChildRemoved } from 'firebase/database';
import { debounce } from 'lodash';

export default {
  name: 'AR Master',
  components: {Vue3EasyDataTable},
  data(){
    return {
      filelist: [],
      searchValue: '',
      sortBy: 'customer_name',
      sortType: 'asc',
      table_height: 400,
      headers: [
        { text: "Customer Number", value: "customer_number", sortable: true },
        { text: "Customer Name", value: "customer_name", sortable: true },
        { text: "31+ Days Past Due Balance", value: "past_due_31_more_balance", sortable: true },
        // { text: "A/R Contact", value: "ar_contact", sortable: true },
        // { text: "A/R Phone", value: "ar_phone", sortable: true },
        { text: "A/R Balance", value: "ar_balance", sortable: true },
        { text: "A/R Balance", value: "ar_balance", sortable: true },
        { text: "Open Order Balance", value: "open_order_balance", sortable: true },
        { text: "Credit Limit", value: "credit_limit", sortable: true },
        { text: "Credit Status", value: "credit_status", sortable: true },
        { text: "Current", value: "current", sortable: true },
        { text: "1 - 30", value: "1_30", sortable: true },
        { text: "31 - 60", value: "31_60", sortable: true },
        { text: "61 - 90", value: "61_90", sortable: true },
        { text: "91 & Over", value: "91_over", sortable: true },
        { text: "Account Executive", value: "account_executive", sortable: true },
        { text: "Collections Inquiry Email", value: "collections_inquiry_emails", sortable: true },
      ],
      ar_table_data: []
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }    
    this.$store.state.isLoading = true
  },
  mounted() {
    setTimeout(() => {
      const db = getDatabase();
      const arRef = ref(db, 'accounts_receivable');

      this.onChildAddedListener = onChildAdded(arRef, (snapshot) => {
        const ar = snapshot.val();
        ar.id = snapshot.key;
        ar.account_executive = this.db.customers[ar.id].salesperson_code
        ar.credit_status = this.db.customers[ar.id].credit_status
        ar.collections_inquiry_emails = this.db.customers[ar.id].collections_inquiry_emails
        ar.past_due_31_more_balance = ar['31_60'] + ar['61_90'] + ar['91_over']
        this.ar_table_data.push(ar);
        this.$store.state.isLoading = false;
      });

      this.onChildChangedListener = onChildChanged(arRef, (snapshot) => {
        const ar = snapshot.val();
        ar.id = snapshot.key;
        ar.account_executive = this.db.customers[ar.id].salesperson_code
        ar.credit_status = this.db.customers[ar.id].credit_status
        ar.collections_inquiry_emails = this.db.customers[ar.id].collections_inquiry_emails
        ar.past_due_31_more_balance = ar['31_60'] + ar['61_90'] + ar['91_over']
        const index = this.ar_table_data.findIndex((i) => i.id === ar.id);
        if (index !== -1) {
          this.ar_table_data.splice(index, 1, ar);
        }
      });

      this.onChildRemovedListener = onChildRemoved(arRef, (snapshot) => {
        const id = snapshot.key;
        const index = this.ar_table_data.findIndex((ar) => ar.id === id);
        if (index !== -1) {
          this.ar_table_data.splice(index, 1);
        }
      });

    }, 1000);
  },
  beforeUnmount() {
    if (this.onChildAddedListener) {
      this.onChildAddedListener();
    }
    if (this.onChildChangedListener) {
      this.onChildChangedListener();
    }
    if (this.onChildRemovedListener) {
      this.onChildRemovedListener();
    }
  },
  computed: {
    ...mapGetters(['user','db'])
  },
  methods: {
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 260
    },
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value.replace(/\$/g, '')
    }, 500),
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    formatCurrency(value) {
      const absoluteValue = Math.abs(value);
      const sign = value < 0 ? "-" : "";
      const formattedValue = absoluteValue.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return `${sign}$${formattedValue}`;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .table-container{
    position: relative;
    top: 5px;
    margin:0px 10px;
  }
</style>
