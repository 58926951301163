<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">

      <v-card-text>
        <v-card-title style="margin-left: -15px; color: gray;">FAQ</v-card-title>
        <div class="faq-container">
          <div v-for="(item, index) in faq" :key="index" class="faq-item">
            <div class="question" style="color:#ff7214;">{{ item.question }}</div>
            <div class="answer">{{ item.answer }}</div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CLOSE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FaqPopUp',
  components: {},
  data(){
    return{
      dialog: true,
      faq:[
        {
          question: "What is this website?",
          answer: "At Riverhawk Industrial Supply, we recognize how important accurate product ETAs are to your customer experience, so we’ve invested in technology to help keep you informed. This website, called Riverhawk Nest, will provide more visibility into your backordered items and ETAs."
        },
        {
          question: "Why are some of my orders missing from this page?",
          answer: "This tool is for tracking backordered product. If you do not see an item listed, it is probably because we have the product in stock and we anticipate that it will be delivered and invoiced soon. We are also not displaying repair orders at this time. Please contact your salesperson if you need more information about an open order or repair."
        },
        {
          question: "How can I add or remove a contact from this email distribution list?",
          answer: "To update the recipient list, please email your changes to sales@riverhawkindustrialsupply.com."
        },
        {
          question: "Will this website link change from week to week?",
          answer: "No, your link will remain the same each week and is specific to your company. You may share it with anyone else at your company."
        },
        {
          question: "How often will I receive these proactive ETA emails?",
          answer: "Our goal is to proactively send the link once a week unless you tell us otherwise. However, please feel free to bookmark the website address and access it whenever you would like. The data is updated multiple times per day and Riverhawk will be reviewing any delays at least weekly."
        },
        {
          question: "I need help finding a substitute or a different source for a product. What should I do?",
          answer: "Please use the “Inquire” button next to the product to let your ETA manager know."
        },
        {
          question: "I have feedback about this new tool. How can I share it with Riverhawk?",
          answer: "Please use the “Share Your Feedback” button at the bottom of the website."
        }
      ]
    }
  }
}
</script>

<style scoped>
.faq-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  line-height:120%;
}

.faq-item {
  border: 1px solid #ccc;
  padding: 5px;
}

.question {
  font-weight: bold;
  margin-bottom: 5px;
}

.answer {
  white-space: pre-line;
  color:gray;
  font-size:14px;
}
</style>


