<template>
  <div class="vendors-import">

    <div class="dropzone bg-white" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input type="file" name="fields[assetsFieldHandle_ar][]" id="assetsFieldHandle_ar" @change="onChange" ref="file" style="opacity: 0;display:none"/>
      <div v-if="!filelist.length" style="padding-top:10px;padding-left:25px;color:gray;display:flex;align-items:center;">
        <v-icon class="mdi mdi-file-upload-outline orange--text"></v-icon>
        <span style="padding-left:10px;">A/R File Drop</span>
      </div>
    </div>
    <div style="color:red;font-size:10px;font-weight:bold;padding-top:5px;color:white">.</div>
    <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;position:relative;">
      <div style="position:absolute;right:0px;bottom:2px">
        <div style="font-size:12px;color:gray;font-style:italic;">Last Upload Date: {{lastUploadDate}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as XLSX from "xlsx"
import Papa from "papaparse"
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import AWS from 'aws-sdk';

export default {
  name: 'ARImport',
  components: {

  },
  data(){
    return {
      filelist: []
    }
  },
  watch: {
    filelist(val){
      if(val.length > 0){
        this.readFiles()
      }
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    lastUploadDate(){
      return Moment(this.db.last_ar_file_upload).format('MM/DD/YY hh:mma')
    }
  },
  methods: {
    searchFiles(){
      document.getElementById("assetsFieldHandle_ar").click()
    },
    onChange() {
      var files_arr = [...this.$refs.file.files];
      files_arr = _.sortBy(files_arr, (obj) => parseInt(obj.name, 10));
      this.filelist = files_arr;
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-orange')) {
        event.currentTarget.classList.remove('bg-white');
        event.currentTarget.classList.add('bg-orange');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
      this.onChange();
    },
    readFiles(){
      for(var i = 0; i < this.filelist.length; i++){
        var file_name = this.filelist[i].name.toLowerCase()
        if(this.filelist[i].type.indexOf('text/csv') !== -1 && file_name.indexOf('ar') !== -1){
          this.writeInitialData(this.filelist[i], Date.now())
        }else{
          this.$store.state.isLoading = false
          this.remove(this.filelist[i])
          alert('Invalid File(s)')
        }
      }
    },
    writeInitialData(file,file_date){
      var reader = new FileReader();
      var self = this
      self.$store.state.isLoading = true
      reader.readAsBinaryString(file);

      reader.onload = function(e) {
        var data = e.target.result;
        var parsedData = Papa.parse(data, {
          header: true, // Set header to true to parse the first row as headers
          dynamicTyping: false, // Disable automatic type casting
          skipEmptyLines: true, // Skip empty lines
        });

        var json = parsedData.data;

        if (json.length === 0) {
          self.$store.state.isLoading = false;
          alert('Invalid File(s)');
          return;
        }

        setTimeout(function(){
          self.updateDatabase(json,file_date)
        },1000)
      }
    },
    updateDatabase(json,file_date){
      var self = this
      var db_object = {}
      var updates = {};
      var date_now = Date.now()
      
      var current_customer_numbers_in_db = []
      var uploaded_customer_numbers = []

      for(var c in this.db.accounts_receivable){
        current_customer_numbers_in_db.push(c)
      }

      for(var i = 0; i < json.length; i++){
        var row_data = json[i]
        var ar_info = {
            'customer_number': parseInt(row_data['Customer#'], 10).toString(),
            'customer_name': row_data['Customer Name'] ? row_data['Customer Name'] : '',
            'ar_contact': row_data['A/R Contact'] ? row_data['A/R Contact'] : '',
            'ar_phone': row_data['A/R Phone'] ? row_data['A/R Phone'] : '',
            'ar_balance': row_data['A/R Balance'] ? parseFloat(row_data['A/R Balance'].replace(/,/g, '')) : 0,
            'open_order_balance': row_data['Open Order Balance'] ? parseFloat(row_data['Open Order Balance'].replace(/,/g, '')) : 0,
            'oi_days': row_data['OI Days'] ? row_data['OI Days'] : '',
            'credit_limit': row_data['Credit Limit'] ? parseFloat(row_data['Credit Limit'].replace(/,/g, '')) : 0,
            'current': row_data['Current'] ? parseFloat(row_data['Current'].replace(/,/g, '')) : 0,
            '1_30': row_data['1  - 30'] ? parseFloat(row_data['1  - 30'].replace(/,/g, '')) : 0,
            '31_60': row_data['31 - 60'] ? parseFloat(row_data['31 - 60'].replace(/,/g, '')) : 0,
            '61_90': row_data['61 - 90'] ? parseFloat(row_data['61 - 90'].replace(/,/g, '')) : 0,
            '91_over': row_data['91 & Over'] ? parseFloat(row_data['91 & Over'].replace(/,/g, '')) : 0,
            'upload_date': date_now,
            'upload_by': self.user
        }

        var current_db_ar_customer = this.db.accounts_receivable ? this.db.accounts_receivable['customer_' + ar_info.customer_number] : false

        uploaded_customer_numbers.push('customer_'+ar_info.customer_number)

        if(current_db_ar_customer){
          var current_db_past_due_31_more_balance = current_db_ar_customer['31_60'] + current_db_ar_customer['61_90'] + current_db_ar_customer['91_over']
          var new_past_due_31_more_balance = ar_info['31_60'] + ar_info['61_90'] + ar_info['91_over']
          var difference = new_past_due_31_more_balance - current_db_past_due_31_more_balance
          if(difference < 0){
            var current_reminders_sent = current_db_ar_customer.reminders_sent
            for(var cr in current_reminders_sent){
              updates[`/accounts_receivable/customer_${ar_info.customer_number}/reminders_sent/${cr}/archive`] = true;
            }
          }
        }
        
        for (var key in ar_info) {
          if(ar_info.customer_number !== 'NaN'){
            updates[`/accounts_receivable/${'customer_' + ar_info.customer_number}/${key}`] = ar_info[key];  
          }
        }
        if(ar_info.customer_number !== 'NaN'){
          ar_info.past_due_31_more_balance = ar_info['31_60'] + ar_info['61_90'] + ar_info['91_over']
          ar_info.credit_status = this.db.customers['customer_'+ar_info.customer_number].credit_status
          updates[`/accounts_receivable/customer_${ar_info.customer_number}/historic/${Date.now()}`] = ar_info
        }
      }
      
      updates['/last_ar_file_upload'] = file_date;

      for(var c = 0; c < current_customer_numbers_in_db.length; c++){
        var index_in_uploaded = uploaded_customer_numbers.indexOf(current_customer_numbers_in_db[c])
        if(index_in_uploaded === -1){
          var current_reminders_sent = this.db.accounts_receivable[current_customer_numbers_in_db[c]].reminders_sent
          for(var cr in current_reminders_sent){
            updates[`/accounts_receivable/${current_customer_numbers_in_db[c]}/reminders_sent/${cr}/archive`] = true;
          }
          var updateData = {
            'upload_date': date_now,
            'open_order_balance': 987654321,
            'ar_balance': 987654321,
            'current': 987654321,
            '1_30': 0,
            '31_60': 0,
            '61_90': 0,
            '91_over': 0
          };
          for (var key in updateData) {
            updates[`/accounts_receivable/${current_customer_numbers_in_db[c]}/${key}`] = updateData[key];
          }
        }
      }

      firebase.database().ref().update(updates)
      self.filelist = []
      self.$store.state.isLoading = false      
    }
  }
}
</script>

<style scoped>
  .dropzone{
    width: 100%;
    height: 50px;
    border: 2px dashed orange;
    border-radius: 4px;
  }
  .bg-white{
    background: white;
  }
  .bg-orange{
    background: orange;
  }
</style>
